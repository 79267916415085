import {
    REQUEST_BANK_ENTRIES,
    RECEIVE_BANK_ENTRIES,
    ERROR_BANK_ENTRIES
} from '../types'
import {initialFetchState} from '../../constants/defaultValues'

export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_BANK_ENTRIES:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_BANK_ENTRIES:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                errors: {},
                message: '',
            }
        case ERROR_BANK_ENTRIES:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                message: action.errors.server || '',
            }
        default:
            return state
    }
}