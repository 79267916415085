import React from "react"
import SinglePageLayout from "../layout/SinglePageLayout"
import Error from "../components/common/Error"

export default () => {
    return (
        <SinglePageLayout>
            <Error server={true}/>
        </SinglePageLayout>
    )
}
