import React from "react"
import Fa from "../Fa"

export default (props) => {
    const {field, data} = props
    let itm = null
    let variant = 'primary'

    if(typeof field.ifCond !== "undefined"){
        let filled = false
        if(field.ifCond == data){
            itm = <span className={`icon text-${field.ifVariant || variant}`}><Fa n={field.ifVal}/></span>
            filled = true
        }else if(typeof field.ifCond == 'object'){
            field.ifCond.map((i, idx) => {
                if(i === data){
                    itm = <span className={`icon text-${field.ifVariant[idx] || variant}`}><Fa n={field.ifVal[idx]}/></span>
                    filled = true
                }
            })
        }
        if(typeof field.elseVal !== "undefined" && !filled){
            itm = <span className={`icon text-${field.elseVariant || field.variant || variant}`}><Fa n={field.elseVal}/></span>
        }
    }else{
        const s = data
        if(field.variants){
            variant = field.variants[data]
        }
        if(field.variant){ // force override variant
            variant = field.variant
        }
        itm = <span className={`${props.className} icon text-${variant}`}><Fa n={s}/></span>
    }

    return itm
}