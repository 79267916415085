/*
LocalStorage keys
*/
export const storage = {
    authentication: 'Authorization_' + btoa(window.location.host),
    sidebar: '__sidebar',
    cookie_settings: '__cs'
}

export const defaultTheme = "light.default"
export const themeOptions = [
    "light.default",
    "dark.default"
]

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-sub-hidden"

export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768

// Toaster
export const defaultToaster = {
    toasterType: "OK",
    title: '',
    message: '',
    counter: 0
}

//fetch state
export const initialFetchState = {
    data: {},
    isFetching: false,
    shouldReload: false,
    errors: {},
    message: ''
}

//fetch documents state
export const initialPdfDocumentsFetchState = {}

//fetch sequence state
export const initialSequenceFetchState = {
    data: [],
    isFetching: false,
    errors: {},
    message: ''
}

const years = {}
let year = new Date()
year = year.getFullYear()
while (year > 2018){
    years[year.toString()] = {value: year.toString(), label: year.toString()}
    year--
}

const months = {}
let month = 1
while (month < 13){
    months[month] = {value: month, label: month}
    month++
}

const cashflowDays = {}
let day = 1
while (day < 31){
    cashflowDays[day] = {value: day, label: day}
    day++
}

const expenseDescriptions = {};
[
    "Škoda KAMIQ: tankování",
    "Dacia JOGGER: tankování",
    "Dacia DOKKER: tankování",
    "iQiosk: montážní materiál, příslušenství a komponenty",
    "iQiosk: výroba (výroba komponentů/svařování/lakování)",
    "Kancelář/dílna: vybavení/provozní materiál"
].map((itm, idx) => {
    expenseDescriptions[idx] = {value: itm, label: itm}
})

export const years_list = Object.keys(years)

//enum fetch state
export const initialEnumFetchState = {
    'filter-boolean': {
        data: [
            {value: 1, label: 'yes'},
            {value: 0, label: 'no'},
            {value: null, label: 'any'},
        ],
        isFetching: false,
        shouldReload: false
    },
    'warranty-list': {
        data: [
            {value: 12, label: '12'},
            {value: 24, label: '24'},
            {value: 36, label: '36'},
            {value: 48, label: '48'},
            {value: 60, label: '60'}
        ],
        isFetching: false,
        shouldReload: false
    },
    'pdf-actions': {
        data: {
            download: {value: 'download', label: 'download'},
            show: {value: 'show', label: 'show'},
        },
        isFetching: false,
        shouldReload: false
    },
    'vats': {
        data: {
            '21': {value: '21', label: '21 %'}
        },
        isFetching: false,
        shouldReload: false
    },
    'importance': {
        data: {
            '0': {value: '0', label: 'low'},
            '1': {value: '1', label: 'medium'},
            '2': {value: '2', label: 'high'},
        },
        isFetching: false,
        shouldReload: false
    },
    'order-status': {
        data: {
            '0': {value: '0', label: 'waiting'},
            '1': {value: '1', label: 'working'},
            '2': {value: '2', label: 'finished'},
        },
        isFetching: false,
        shouldReload: false
    },
    'order-payment-methods': {
        data: {
            '0': {value: '0', label: 'one-time'},
            '1': {value: '1', label: 'lease'},
            '2': {value: '2', label: 'repayment'},
        },
        isFetching: false,
        shouldReload: false
    },
    'invoice-status': {
        data: {
            '0': {value: '0', label: 'unpaid'},
            '1': {value: '1', label: 'paid'},
            '2': {value: '2', label: 'canceled'},
            '3': {value: '3', label: 'overdue', isDisabled: true},
        },
        isFetching: false,
        shouldReload: false
    },
    'specializations': {
        data: {
            '0': {value: '0', label: 'it'},
            '1': {value: '1', label: 'drone-work'},
            '2': {value: '2', label: 'iqiosk'},
        },
        isFetching: false,
        shouldReload: false
    },
    'invoice-categories': {
        data: {
            'main': {value: 'main', label: 'inv-cat-main'},
            'prophylaxis': {value: 'prophylaxis', label: 'inv-cat-prophylaxis'},
            'tariff': {value: 'tariff', label: 'inv-cat-tariff'},
            'service': {value: 'service', label: 'inv-cat-service'},
            'other': {value: 'other', label: 'inv-cat-other'},
            'hosting': {value: 'hosting', label: 'inv-cat-hosting'},
            'lease': {value: 'lease', label: 'inv-cat-lease'},
            'repayment': {value: 'repayment', label: 'inv-cat-repayment'}
        },
        isFetching: false,
        shouldReload: false
    },
    'regular-invoice-categories': {
        data: {
            'tariff': {value: 'tariff', label: 'inv-cat-tariff'},
            'hosting': {value: 'hosting', label: 'inv-cat-hosting'},
            'lease': {value: 'lease', label: 'inv-cat-lease'},
            'repayment': {value: 'repayment', label: 'inv-cat-repayment'},
        },
        isFetching: false,
        shouldReload: false
    },
    'regular-invoice-frequencies': {
        data: {
            '12': {value: '12', label: 'invoice-freq-monthly'},
            '4': {value: '4', label: 'invoice-freq-quarterly'},
            '2': {value: '2', label: 'invoice-freq-semi-annually'},
            '1': {value: '1', label: 'invoice-freq-annually'},
        },
        isFetching: false,
        shouldReload: false
    },
    'invoice-type': {
        data: {
            '0': {value: '0', label: 'invoice'},
            '1': {value: '1', label: 'invoice-proforma'},
            '2': {value: '2', label: 'proforma-partial'},
            '3': {value: '3', label: 'proforma-full'},
        },
        isFetching: false,
        shouldReload: false
    },
    'regular-invoice-type': {
        data: {
            '0': {value: '0', label: 'invoice'},
            '3': {value: '3', label: 'proforma-full'},
        },
        isFetching: false,
        shouldReload: false
    },
    'expense-type': {
        data: {
            '0': {value: '0', label: 'invoice'},
            '1': {value: '1', label: 'receipt'},
        },
        isFetching: false,
        shouldReload: false
    },
    'expense-payment': {
        data: {
            '0': {value: '0', label: 'bank'},
            '1': {value: '1', label: 'card'},
            '2': {value: '2', label: 'cash'}
        },
        isFetching: false,
        shouldReload: false
    },
    'commission_source': {
        data: {
            '0': {value: '0', label: 'main'},
            '1': {value: '1', label: 'tariff'},
            '2': {value: '2', label: 'prophylaxis'},
            '3': {value: '3', label: 'service'},
        },
        isFetching: false,
        shouldReload: false
    },
    'offer-status': {
        data: {
            '0': {value: '0', label: 'offer-new'},
            '1': {value: '1', label: 'offer-in-progress'},
            '2': {value: '2', label: 'offer-approved'},
            '3': {value: '3', label: 'offer-postponed'},
            '4': {value: '4', label: 'offer-finished'},
            '5': {value: '5', label: 'offer-rejected'}
        },
        isFetching: false,
        shouldReload: false
    },
    'tax-entry-type': {
        data: {
            '0': {value: '0', label: 'overpaid'},
            '1': {value: '1', label: 'underpaid'},
        },
        isFetching: false,
        shouldReload: false
    },
    'cashflow-ref-days': {
        data: cashflowDays,
        isFetching: false,
        shouldReload: false
    },
    'cashflow-ref-months': {
        data: {
            0: {value: 0, label: 0},
            ...months
        },
        isFetching: false,
        shouldReload: false
    },
    'years': {
        data: years,
        isFetching: false,
        shouldReload: false
    },
    'months': {
        data: months,
        isFetching: false,
        shouldReload: false
    },
    'expense-description-picker': {
        data: expenseDescriptions,
        isFetching: false,
        shouldReload: false
    }
}

//enum fetch Item state
export const initialEnumItemFetchState = {
    data: [],
    isFetching: false,
    shouldReload: false
}

//user state
export const initialUserFetchState = {
    isAuthenticated: false,
    isFetching: false,
    user: {},
}
