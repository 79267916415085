/*TOASTER*/
export const SET_TOASTER = "SET_TOASTER"
export const CHANGE_THEME = "CHANGE_THEME"

/* TABS */
export const SET_TAB = "SET_TAB"

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES"
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME"
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU"
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = "MENU_CHANGE_HAS_SUB_ITEM_STATUS"

/* FILTERS */
export const SET_FILTER_DATA = "SET_FILTER_DATA"
export const TOGGLE_FILTER = "TOGGLE_FILTER"

/* AUTH */
export const REQUEST_CURRENT_USER = "REQUEST_CURRENT_USER"
export const RECEIVE_CURRENT_USER = "RECEIVE_CURRENT_USER"
export const ERROR_CURRENT_USER = "ERROR_CURRENT_USER"

/* INFO */
export const REQUEST_INFO = "REQUEST_INFO"
export const RECEIVE_INFO = "RECEIVE_INFO"
export const ERROR_INFO = "ERROR_INFO"

/* STRINGS */
export const REQUEST_STRINGS = "REQUEST_STRINGS"
export const RECEIVE_STRINGS = "RECEIVE_STRINGS"
export const UPDATE_STRINGS = "UPDATE_STRINGS"
export const ERROR_STRINGS = "ERROR_STRINGS"
export const TOGGLE_DEV_STRINGS = "TOGGLE_DEV_STRINGS"

/* ADMIN STRINGS */
export const REQUEST_ADMIN_STRINGS = "REQUEST_ADMIN_STRINGS"
export const RECEIVE_ADMIN_STRINGS = "RECEIVE_ADMIN_STRINGS"
export const UPDATE_ADMIN_STRINGS = "UPDATE_ADMIN_STRINGS"
export const ERROR_ADMIN_STRINGS = "ERROR_ADMIN_ROLES"

/* ADMINS */
export const REQUEST_USERS = "REQUEST_USERS"
export const RECEIVE_USERS = "RECEIVE_USERS"
export const UPDATE_USERS = "UPDATE_USERS"
export const ERROR_USERS = "ERROR_USERS"

/* ROLES */
export const REQUEST_ROLES = "REQUEST_ROLES"
export const RECEIVE_ROLES = "RECEIVE_ROLES"
export const UPDATE_ROLES = "UPDATE_ROLES"
export const ERROR_ROLES = "ERROR_ROLES"

/* PERMISSIONS */
export const REQUEST_PERMISSIONS = "REQUEST_PERMISSIONS"
export const RECEIVE_PERMISSIONS = "RECEIVE_PERMISSIONS"
export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS"
export const ERROR_PERMISSIONS = "ERROR_PERMISSIONS"

/* PDF-DOCUMENTS */
export const REQUEST_PDF_DOCUMENTS = "REQUEST_PDF_DOCUMENTS"
export const RECEIVE_PDF_DOCUMENTS = "RECEIVE_PDF_DOCUMENTS"
export const UPDATE_PDF_DOCUMENTS = "UPDATE_PDF_DOCUMENTS"
export const ERROR_PDF_DOCUMENTS = "ERROR_PDF_DOCUMENTS"

/* DOCUMENTS */
export const REQUEST_DOCUMENTS = "REQUEST_DOCUMENTS"
export const RECEIVE_DOCUMENTS = "RECEIVE_DOCUMENTS"
export const UPDATE_DOCUMENTS = "UPDATE_DOCUMENTS"
export const ERROR_DOCUMENTS = "ERROR_DOCUMENTS"


/* NEWS */
export const REQUEST_NEWS = "REQUEST_NEWS"
export const RECEIVE_NEWS = "RECEIVE_NEWS"
export const UPDATE_NEWS = "UPDATE_NEWS"
export const ERROR_NEWS = "ERROR_NEWS"

/* DOC-CATEGORIES */
export const REQUEST_DOC_CATEGORIES = "REQUEST_DOC_CATEGORIES"
export const RECEIVE_DOC_CATEGORIES = "RECEIVE_DOC_CATEGORIES"
export const UPDATE_DOC_CATEGORIES = "UPDATE_DOC_CATEGORIES"
export const ERROR_DOC_CATEGORIES = "ERROR_DOC_CATEGORIES"

/* PASSWORDS */
export const REQUEST_PASSWORDS = "REQUEST_PASSWORDS"
export const RECEIVE_PASSWORDS = "RECEIVE_PASSWORDS"
export const UPDATE_PASSWORDS = "UPDATE_PASSWORDS"
export const ERROR_PASSWORDS = "ERROR_PASSWORDS"

/* PLACES */
export const REQUEST_PLACES = "REQUEST_PLACES"
export const RECEIVE_PLACES = "RECEIVE_PLACES"
export const UPDATE_PLACES = "UPDATE_PLACES"
export const ERROR_PLACES = "ERROR_PLACES"

/* EVENTS */
export const REQUEST_EVENTS = "REQUEST_EVENTS"
export const RECEIVE_EVENTS = "RECEIVE_EVENTS"
export const UPDATE_EVENTS = "UPDATE_EVENTS"
export const ERROR_EVENTS = "ERROR_EVENTS"

/* PILOTS */
export const REQUEST_PILOTS = "REQUEST_PILOTS"
export const RECEIVE_PILOTS = "RECEIVE_PILOTS"
export const UPDATE_PILOTS = "UPDATE_PILOTS"
export const ERROR_PILOTS = "ERROR_PILOTS"

/* DRONES */
export const REQUEST_DRONES = "REQUEST_DRONES"
export const RECEIVE_DRONES = "RECEIVE_DRONES"
export const UPDATE_DRONES = "UPDATE_DRONES"
export const ERROR_DRONES = "ERROR_DRONES"

/* FLIGHTS */
export const REQUEST_DRONE_FLIGHTS = "REQUEST_DRONE_FLIGHTS"
export const RECEIVE_DRONE_FLIGHTS = "RECEIVE_DRONE_FLIGHTS"
export const UPDATE_DRONE_FLIGHTS = "UPDATE_DRONE_FLIGHTS"
export const ERROR_DRONE_FLIGHTS = "ERROR_DRONE_FLIGHTS"

/* DRONE_MAINTENANCE */
export const REQUEST_DRONE_MAINTENANCE = "REQUEST_DRONE_MAINTENANCE"
export const RECEIVE_DRONE_MAINTENANCE = "RECEIVE_DRONE_MAINTENANCE"
export const UPDATE_DRONE_MAINTENANCE = "UPDATE_DRONE_MAINTENANCE"
export const ERROR_DRONE_MAINTENANCE = "ERROR_DRONE_MAINTENANCE"

/* PROPERTY */
export const REQUEST_PROPERTY = "REQUEST_PROPERTY"
export const RECEIVE_PROPERTY = "RECEIVE_PROPERTY"
export const UPDATE_PROPERTY = "UPDATE_PROPERTY"
export const ERROR_PROPERTY = "ERROR_PROPERTY"

/* SUPPLIERS */
export const REQUEST_SUPPLIERS = "REQUEST_SUPPLIERS"
export const RECEIVE_SUPPLIERS = "RECEIVE_SUPPLIERS"
export const UPDATE_SUPPLIERS = "UPDATE_SUPPLIERS"
export const ERROR_SUPPLIERS = "ERROR_SUPPLIERS"

/* CUSTOMERS */
export const REQUEST_CUSTOMERS = "REQUEST_CUSTOMERS"
export const RECEIVE_CUSTOMERS = "RECEIVE_CUSTOMERS"
export const UPDATE_CUSTOMERS = "UPDATE_CUSTOMERS"
export const ERROR_CUSTOMERS = "ERROR_CUSTOMERS"

/* ORDERS */
export const REQUEST_ORDERS = "REQUEST_ORDERS"
export const RECEIVE_ORDERS = "RECEIVE_ORDERS"
export const UPDATE_ORDERS = "UPDATE_ORDERS"
export const ERROR_ORDERS = "ERROR_ORDERS"

/* OFFERS */
export const REQUEST_OFFERS = "REQUEST_OFFERS"
export const RECEIVE_OFFERS = "RECEIVE_OFFERS"
export const UPDATE_OFFERS = "UPDATE_OFFERS"
export const ERROR_OFFERS = "ERROR_OFFERS"

/* EXPENSES */
export const REQUEST_EXPENSES = "REQUEST_EXPENSES"
export const RECEIVE_EXPENSES = "RECEIVE_EXPENSES"
export const UPDATE_EXPENSES = "UPDATE_EXPENSES"
export const ERROR_EXPENSES = "ERROR_EXPENSES"
export const RELOAD_EXPENSES = "RELOAD_EXPENSES"

/* REGULAR_EXPENSES */
export const REQUEST_REGULAR_EXPENSES = "REQUEST_REGULAR_EXPENSES"
export const RECEIVE_REGULAR_EXPENSES = "RECEIVE_REGULAR_EXPENSES"
export const UPDATE_REGULAR_EXPENSES = "UPDATE_REGULAR_EXPENSES"
export const ERROR_REGULAR_EXPENSES = "ERROR_REGULAR_EXPENSES"

/* INVOICES */
export const REQUEST_INVOICES = "REQUEST_INVOICES"
export const RECEIVE_INVOICES = "RECEIVE_INVOICES"
export const UPDATE_INVOICES = "UPDATE_INVOICES"
export const ERROR_INVOICES = "ERROR_INVOICES"

/* REGULAR_INVOICES */
export const REQUEST_REGULAR_INVOICES = "REQUEST_REGULAR_INVOICES"
export const RECEIVE_REGULAR_INVOICES = "RECEIVE_REGULAR_INVOICES"
export const UPDATE_REGULAR_INVOICES = "UPDATE_REGULAR_INVOICES"
export const ERROR_REGULAR_INVOICES = "ERROR_REGULAR_INVOICES"

/* BANK_STATEMENTS */
export const REQUEST_BANK_STATEMENTS = "REQUEST_BANK_STATEMENTS"
export const RECEIVE_BANK_STATEMENTS = "RECEIVE_BANK_STATEMENTS"
export const UPDATE_BANK_STATEMENTS = "UPDATE_BANK_STATEMENTS"
export const ERROR_BANK_STATEMENTS = "ERROR_BANK_STATEMENTS"

/* TAX_ENTRIES */
export const REQUEST_TAX_ENTRIES = "REQUEST_TAX_ENTRIES"
export const RECEIVE_TAX_ENTRIES = "RECEIVE_TAX_ENTRIES"
export const UPDATE_TAX_ENTRIES = "UPDATE_TAX_ENTRIES"
export const ERROR_TAX_ENTRIES = "ERROR_TAX_ENTRIES"

/* BANK_ENTRIES */
export const REQUEST_BANK_ENTRIES = "REQUEST_BANK_ENTRIES"
export const RECEIVE_BANK_ENTRIES = "RECEIVE_BANK_ENTRIES"
export const ERROR_BANK_ENTRIES = "ERROR_BANK_ENTRIES"

/* BANK_TRANSACTIONS */
export const REQUEST_BANK_TRANSACTIONS = "REQUEST_BANK_TRANSACTIONS"
export const RECEIVE_BANK_TRANSACTIONS = "RECEIVE_BANK_TRANSACTIONS"
export const UPDATE_BANK_TRANSACTIONS = "UPDATE_BANK_TRANSACTIONS"
export const ERROR_BANK_TRANSACTIONS = "ERROR_BANK_TRANSACTIONS"
export const RELOAD_BANK_TRANSACTIONS = "RELOAD_BANK_TRANSACTIONS"

/* DOCUMENT_TEMPLATES */
export const REQUEST_DOCUMENT_TEMPLATES = "REQUEST_DOCUMENT_TEMPLATES"
export const RECEIVE_DOCUMENT_TEMPLATES = "RECEIVE_DOCUMENT_TEMPLATES"
export const UPDATE_DOCUMENT_TEMPLATES = "UPDATE_DOCUMENT_TEMPLATES"
export const ERROR_DOCUMENT_TEMPLATES = "ERROR_DOCUMENT_TEMPLATES"

/* INTERNAL_BANK_TRANSFERS */
export const REQUEST_INTERNAL_BANK_TRANSFERS = "REQUEST_INTERNAL_BANK_TRANSFERS"
export const RECEIVE_INTERNAL_BANK_TRANSFERS = "RECEIVE_INTERNAL_BANK_TRANSFERS"
export const UPDATE_INTERNAL_BANK_TRANSFERS = "UPDATE_INTERNAL_BANK_TRANSFERS"
export const ERROR_INTERNAL_BANK_TRANSFERS = "ERROR_INTERNAL_BANK_TRANSFERS"

/* COMMISSIONS */
export const REQUEST_COMMISSIONS = "REQUEST_COMMISSIONS"
export const RECEIVE_COMMISSIONS = "RECEIVE_COMMISSIONS"
export const UPDATE_COMMISSIONS = "UPDATE_COMMISSIONS"
export const ERROR_COMMISSIONS = "ERROR_COMMISSIONS"

/* DASHBOARD */
export const REQUEST_DASHBOARD = "REQUEST_DASHBOARD"
export const RECEIVE_DASHBOARD = "RECEIVE_DASHBOARD"
export const RELOAD_DASHBOARD = "RELOAD_DASHBOARD"
export const UPDATE_DASHBOARD = "UPDATE_DASHBOARD"
export const ERROR_DASHBOARD = "ERROR_DASHBOARD"

/* CASHFLOW */
export const REQUEST_CASHFLOW = "REQUEST_CASHFLOW"
export const RECEIVE_CASHFLOW = "RECEIVE_CASHFLOW"
export const ERROR_CASHFLOW = "ERROR_CASHFLOW"

/* ENUMS */
export const REQUEST_ENUMS = "REQUEST_ENUMS"
export const RECEIVE_ENUMS = "RECEIVE_ENUMS"
export const RELOAD_ENUMS = "RELOAD_ENUMS"
export const ERROR_ENUMS = "ERROR_ENUMS"

/* ITEM */
export const REQUEST_ITEM = "REQUEST_ITEM"
export const RECEIVE_ITEM = "RECEIVE_ITEM"
export const UPDATE_ITEM = "UPDATE_ITEM"
export const ERROR_ITEM = "ERROR_ITEM"

/* SEQUENCE */
export const REQUEST_SEQUENCE = "REQUEST_SEQUENCE"
export const RECEIVE_SEQUENCE = "RECEIVE_SEQUENCE"
export const ERROR_SEQUENCE = "ERROR_SEQUENCE"
