import {
    REQUEST_PASSWORDS,
    RECEIVE_PASSWORDS,
    UPDATE_PASSWORDS,
    ERROR_PASSWORDS
} from '../types'
import {fetchUrl} from '../../constants/constants'
import {requestItem, receiveItem, failedItem, requestSequence, receiveSequence, failedSequence} from "../actions"
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'
import prepareFilters from "../../helpers/prepareFilters";

function request() {
    return {
        type: REQUEST_PASSWORDS
    };
}

export function receive(data) {
    return {
        type: RECEIVE_PASSWORDS,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_PASSWORDS,
        errors: data
    }
}

function update(data, id, updateType) {
    return {
        type: UPDATE_PASSWORDS,
        id,
        payload: data,
        updateType
    }
}

export function getPasswords(params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().passwords, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'passwords',
                params: prepareFilters(params, getState(), "passwords")
            }).then(res => {
                dispatch(receive(res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function getPassword(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('password'))
            return axios({
                method: 'GET',
                url: fetchUrl + 'passwords/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function createPassword(data) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'POST',
                url: fetchUrl + 'passwords',
                data
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_created'))
                dispatch(update(res.data.data, false, 'create'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function updatePassword(data, id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('password'))
            return axios({
                method: 'PUT',
                url: fetchUrl + 'passwords/' + id,
                data
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function deletePassword(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'DELETE',
                url: fetchUrl + 'passwords/' + id
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_deleted'))
                dispatch(update(res.data.data, id, 'delete'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function getPasswordSequence() {
    return (dispatch, getState) => {
        if (shouldFetch(getState().sequence)) {
            dispatch(requestSequence('password'))
            return axios({
                method: 'GET',
                url: fetchUrl + 'passwords/sequence'
            }).then(res => {
                dispatch(receiveSequence(res.data.data))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedSequence(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function updatePasswordSequence(data) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().sequence)) {
            dispatch(requestSequence('password'))
            return axios({
                method: 'PUT',
                url: fetchUrl + 'passwords/sequence',
                data
            }).then(res => {
                dispatch(receiveSequence(res.data.data, 'message.sequence_saved'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedSequence(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}