import React from 'react'
import IntlMessages from "../../helpers/IntlMessages"
import {BanIcon} from "../svg"
import AppLayout from "../../layout/AppLayout";


export default () => {
    return (
        <AppLayout>
            <div className="empty-message">
                <div className="text-center">
                    <div className="m-auto"><BanIcon/></div>
                    <p className="mt-3 mb-0"><IntlMessages id="general.no_permissions"/></p>
                    <small><IntlMessages id="general.no_permissions_info"/></small>
                </div>
            </div>
        </AppLayout>
    )
}

