import React, {useState, useEffect} from "react"
import {connect} from "react-redux"
import {bindActionCreators} from "redux"
import {toggleDevStrings} from '../../redux/actions'
import {routes} from "../../constants/constants"
import {Row, Col} from 'react-bootstrap'
import {DevIcon, CloseIcon, DesktopIcon} from '../svg'
import StringsIcon from '../svg/menu/Strings'
import AdminIcon from '../svg/menu/Shield'
import {Link, useLocation} from "react-router-dom"
import InfoItem from ".//InfoItem"
import {checkPermission} from "../../helpers/checkPermission"
import _isEmpty from 'lodash/isEmpty'

const Dev = (props) => {
    const {info, auth, isDevStrings} = props
    const isAuthenticated = auth.isAuthenticated
    const location = useLocation()
    const [visible, setVisible] = useState(false)
    const [devMode, setDevMode] = useState(false)
    const [devStrings, setDevStrings] = useState(isDevStrings)
    const isAdminRoute = location.pathname.includes('admin/')

    useEffect(() => {
        if(isAuthenticated){
            setTimeout(() => {
                setVisible(true)
            }, 2500)
        }
    }, [isAuthenticated])

    useEffect(() => {
        setDevStrings(isDevStrings)
    }, [isDevStrings])

    if(!isAuthenticated || !auth.user.master){
        return null
    }

    const toggleDevMode = () => {
        setDevMode(!devMode)
    }

    const toggleDevStrings = () => {
        setDevStrings(!devStrings)
        props.actions.toggleDevStrings()
    }

    const stringPermission = checkPermission('admin-string.view', auth.user)

    return(
        <div className={`dev ${devMode ? 'active' : ''} ${visible ? 'dev-visible' : ''}`}>
            {
                devMode ? (
                    <div className="dev-inner d-flex flex-column justify-content-between">
                        <div className="close-icon" onClick={toggleDevMode}><CloseIcon/></div>
                        <div>
                            <div><InfoItem value={info.env} label="general.environment" as="span"/></div>
                            <div><InfoItem value={info.locale} label="general.locale" as="span"/></div>
                        </div>
                        <Row>
                            {
                                stringPermission &&
                                    <Col xs={12}>
                                        <button type="button" className={`btn btn-outline-primary ${devStrings ? "active" : ""}`} onClick={toggleDevStrings}><StringsIcon/></button>
                                    </Col>
                            }
                        </Row>
                    </div>
                ) : (
                    <div className="icon-inner" onClick={toggleDevMode}>
                        <DevIcon/>
                    </div>
                )
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        info: state.info.data,
        auth: state.auth,
        isDevStrings: !_isEmpty(state.strings.original)
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            toggleDevStrings
        }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dev)