import {
    SET_FILTER_DATA,
    TOGGLE_FILTER
} from '../types'

export const setFilter = (filterType, data, setOrder = false) => {
    return (
        {
            type: SET_FILTER_DATA,
            payload: data,
            filterType,
            setOrder
        }
    )
}

export const toggleFilter = (filterType) => {
    return (
        {
            type: TOGGLE_FILTER,
            filterType
        }
    )
}

