import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import {routePermissions} from '../constants/constants'

export function checkRoutePermission(route, user) {
    if(user.master){
        return true
    }

    const permissions = routePermissions[route] ? (routePermissions[route].permissions || []) : []
    if (isEmpty(permissions)) {
        return true
    } else {
        return check(permissions, user.permissions)
    }
}

export function checkPermission(permissions, user) {

    if(user.master){
        return true
    }

    if (!isArray(permissions)) {
        permissions = [permissions]
    }

    return check(permissions, user.permissions)
}

function check(permissions, userPermissions){
    if(!userPermissions) return false
    for (let i = 0; i < permissions.length; i++) {
        if (userPermissions.includes(permissions[i])) {
            return true;
        }
    }
    return false
}