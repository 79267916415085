import {
    CHANGE_THEME
} from '../types'

import {storage} from '../../constants/defaultValues'

export const changeColorTheme = (theme) => {
    localStorage.setItem(storage.theme, theme)
    return (
        {
            type: CHANGE_THEME,
            payload: theme
        }
    )
}

