import {
    SET_FILTER_DATA,
    TOGGLE_FILTER
} from '../types'

export default (state = {}, action) => {
    const newState = {...state}
    if(!newState[action.filterType]){
        newState[action.filterType] = {
            isOpen: false,
            filter: {},
            order: {}
        }
    }
    switch (action.type) {
        case TOGGLE_FILTER:
            newState[action.filterType] = {
                ...newState[action.filterType],
                isOpen: !newState[action.filterType].isOpen
            }
            return newState
        case SET_FILTER_DATA:
            newState[action.filterType] = {
                ...newState[action.filterType],
                filter: action.setOrder ? newState[action.filterType].filter : action.payload,
                order: action.setOrder ? action.payload : newState[action.filterType].order
            }
            return newState
        default:
            return state
    }
}