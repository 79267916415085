import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import HelpIcon from "../components/svg/HelpIcon"
import {Popover, OverlayTrigger} from 'react-bootstrap'
import {useIntl} from "react-intl"

const InjectMassage = props => {
    const m = props.intl.messages
    const help = m[`${props.id}_help`]
    return (
        <>
            <FormattedMessage {...props} />
            {
                help && (
                    <OverlayTrigger
                        placement="right"
                        overlay={<Popover id={`pop_${props.id}`}><FormattedMessage {...props} id={`${props.id}_help`} /></Popover>}>
                                    <span data-tip data-for={`pop_${props.id}`} className="hint-universal">
                                      <HelpIcon/>
                                    </span>
                    </OverlayTrigger>
                )
            }
            {
                props.suffix || null
            }
        </>
    )
}

export default injectIntl(InjectMassage, {
    withRef: false
})

export const IntlMessage = id => {
    const messages = useIntl()['messages']
    return messages[id] || id
}
