import {
    REQUEST_DASHBOARD,
    RECEIVE_DASHBOARD,
    RELOAD_DASHBOARD,
    ERROR_DASHBOARD,
    UPDATE_DASHBOARD
} from '../types'
import {fetchUrl} from '../../constants/constants'
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'

function request() {
    return {
        type: REQUEST_DASHBOARD
    };
}

export function receive(data) {
    return {
        type: RECEIVE_DASHBOARD,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_DASHBOARD,
        errors: data
    }
}

export function reloadDashboard() {
    return {
        type: RELOAD_DASHBOARD
    }
}

export function updateDashboard(section, data, id) {
    return {
        type: UPDATE_DASHBOARD,
        section,
        id,
        payload: data
    }
}

export function getDashboard(params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().dashboard, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'dashboard',
                params
            }).then(res => {
                dispatch(receive(res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function getCalendar(params = {}) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().dashboard)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'calendar',
                params
            }).then(res => {
                dispatch(updateDashboard('events', res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function setNotificationRead(id) {
    return dispatch => {
        return axios({
            method: 'PATCH',
            url: fetchUrl + 'notifications/' + id
        }).then(res => {
            dispatch(updateDashboard('notifications', res.data.data, id))
            return true
        }).catch(err => {
            return false
        })
    }
}
