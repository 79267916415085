import {
    REQUEST_SEQUENCE,
    RECEIVE_SEQUENCE,
    ERROR_SEQUENCE
} from '../types'

export function requestSequence(key = '') {
    return {
        type: REQUEST_SEQUENCE,
        payload: key
    };
}

export function receiveSequence(data, message = '') {
    return {
        type: RECEIVE_SEQUENCE,
        payload: data,
        message: message
    }
}

export function failedSequence(data) {
    return {
        type: ERROR_SEQUENCE,
        payload: data
    }
}