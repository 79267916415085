import React from "react"
import {bindActionCreators} from "redux"
import {Link, NavLink} from "react-router-dom"
import {connect} from "react-redux"

import {
    setContainerClassnames,
    clickOnMobileMenu,
    logoutUser
} from "../../redux/actions"

import {MobileMenuIcon, MenuIcon} from "../../components/svg"

import {routes} from "../../constants/constants"
import {Dropdown} from 'react-bootstrap'
import IntlMessages from "../../helpers/IntlMessages"
import Avatar from "../../components/common/Avatar"
import TopnavDarkSwitch from "./Topnav.DarkSwitch"

const TopNav = (props) => {

    const menuButtonClick = (e, menuClickCount, containerClassnames) => {
        e.preventDefault()

        setTimeout(() => {
            let event = document.createEvent("HTMLEvents")
            event.initEvent("resize", false, false)
            window.dispatchEvent(event)
        }, 350)
        props.actions.setContainerClassnames(
            ++menuClickCount,
            containerClassnames,
            props.selectedMenuHasSubItems
        )
    }
    const mobileMenuButtonClick = (e, containerClassnames) => {
        e.preventDefault()
        props.actions.clickOnMobileMenu(containerClassnames)
    }

    const {containerClassnames, menuClickCount} = props

    return (
        <nav className="navbar fixed-top">
            <div className="d-flex align-items-center navbar-left">
                <NavLink
                    to="#" location={{}}
                    className="menu-button d-none d-md-block"
                    onClick={e =>
                        menuButtonClick(e, menuClickCount, containerClassnames)
                    }
                >
                    <MenuIcon/>
                </NavLink>
                <NavLink
                    to="#" location={{}}
                    className="menu-button-mobile d-xs-block d-sm-block d-md-none"
                    onClick={e => mobileMenuButtonClick(e, containerClassnames)}
                >
                    <MobileMenuIcon/>
                </NavLink>
            </div>
            <div className="navbar-logo-wrapper d-flex align-items-center">
                <Link className="navbar-logo" to={routes.landing}>
                    <div className="logo d-flex align-items-center"/>
                </Link>
            </div>
            <div className="navbar-right">
                <div className="user d-inline-block">
                    <Dropdown className="dropdown-menu-right">
                        <Dropdown.Toggle as="button" bsPrefix="p-0 btn btn-empty">
                            <span className="name mr-1">{props.auth.user.name} {props.auth.user.surname}</span>
                            <span><Avatar/></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="mt-3">
                            <Dropdown.Item as="button">
                                <Link to={routes.userProfile}>
                                    {props.auth.user.name} {props.auth.user.surname}
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Divider/>
                            <Dropdown.Item as="button">
                                <div className="d-flex justify-content-between">
                                    <div className="mr-2"><IntlMessages id="general.night-mode"/></div>
                                    <TopnavDarkSwitch/>
                                </div>
                            </Dropdown.Item>
                            <Dropdown.Divider/>
                            <Dropdown.Item as="button" onClick={() => props.actions.logoutUser()}>
                                <IntlMessages id="general.logout"/>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </nav>
    )
}

function mapStateToProps(state) {
    return {
        containerClassnames: state.menu.containerClassnames,
        menuClickCount: state.menu.menuClickCount,
        selectedMenuHasSubItems: state.menu.selectedMenuHasSubItems,
        auth: state.auth,
        info: state.info.data
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            setContainerClassnames,
            clickOnMobileMenu,
            logoutUser
        }, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TopNav)
