import {defaultTheme, themeOptions, storage} from '../../constants/defaultValues'

import {
    CHANGE_THEME
} from '../types'

let loc = window.location.hostname.split('.')
const LOCALE = ['localhost', 'cz'].includes(loc[loc.length - 1]) ? 'cz' : 'cz'

export const locale = (state = LOCALE) => {
    return state
}

const INIT_STATE_THEME = (localStorage.getItem(storage.theme) && themeOptions.filter(x => x === localStorage.getItem(storage.theme)).length > 0) ? localStorage.getItem(storage.theme) : defaultTheme

export const colorTheme = (state = INIT_STATE_THEME, action) => {
    switch (action.type) {
        case CHANGE_THEME:
            return action.payload

        default:
            return state
    }
}