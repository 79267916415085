import {
    REQUEST_BANK_TRANSACTIONS,
    RECEIVE_BANK_TRANSACTIONS,
    UPDATE_BANK_TRANSACTIONS,
    ERROR_BANK_TRANSACTIONS,
    RELOAD_BANK_TRANSACTIONS
} from '../types'
import {fetchUrl} from '../../constants/constants'
import {requestItem, receiveItem, failedItem, reloadDashboard, reloadExpenses} from "../actions"
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'
import prepareFilters from "../../helpers/prepareFilters";

function request() {
    return {
        type: REQUEST_BANK_TRANSACTIONS
    };
}

export function receive(data) {
    return {
        type: RECEIVE_BANK_TRANSACTIONS,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_BANK_TRANSACTIONS,
        errors: data
    }
}

function update(data, id, updateType) {
    return {
        type: UPDATE_BANK_TRANSACTIONS,
        id,
        payload: data,
        updateType
    }
}

export function reloadBankTransactions(){
    return{
        type: RELOAD_BANK_TRANSACTIONS
    }
}

export function getBankTransactions(params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().bankTransactions, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'bank-transactions',
                params: prepareFilters(params, getState(), "bankTransactions")
            }).then(res => {
                dispatch(receive(res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function getBankTransaction(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('bankTransaction'))
            return axios({
                method: 'GET',
                url: fetchUrl + 'bank-transactions/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function updateBankTransaction(data, id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('bankTransaction'))
            return axios({
                method: 'PUT',
                url: fetchUrl + 'bank-transactions/' + id,
                data
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                dispatch(reloadDashboard())
                dispatch(reloadExpenses())
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}