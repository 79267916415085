import {
    REQUEST_DRONE_FLIGHTS,
    RECEIVE_DRONE_FLIGHTS,
    UPDATE_DRONE_FLIGHTS,
    ERROR_DRONE_FLIGHTS
} from '../types'
import {fetchUrl} from '../../constants/constants'
import {requestItem, receiveItem, failedItem} from "../actions"
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'
import prepareFilters from "../../helpers/prepareFilters";

function request() {
    return {
        type: REQUEST_DRONE_FLIGHTS
    };
}

export function receive(data) {
    return {
        type: RECEIVE_DRONE_FLIGHTS,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_DRONE_FLIGHTS,
        errors: data
    }
}

function update(data, id, updateType) {
    return {
        type: UPDATE_DRONE_FLIGHTS,
        id,
        payload: data,
        updateType
    }
}

export function getFlights(params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().flights, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'flights',
                params: prepareFilters(params, getState(), "flights")
            }).then(res => {
                dispatch(receive(res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function getFlight(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('flight'))
            return axios({
                method: 'GET',
                url: fetchUrl + 'flights/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function createFlight(data) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'POST',
                url: fetchUrl + 'flights',
                data
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_created'))
                dispatch(update(res.data.data, false, 'create'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function updateFlight(data, id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('flight'))
            return axios({
                method: 'PUT',
                url: fetchUrl + 'flights/' + id,
                data
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function deleteFlight(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'DELETE',
                url: fetchUrl + 'flights/' + id
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_deleted'))
                dispatch(update(res.data.data, id, 'delete'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}