import {
    REQUEST_CASHFLOW,
    RECEIVE_CASHFLOW,
    ERROR_CASHFLOW
} from '../types'
import {fetchUrl} from '../../constants/constants'
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'

function request() {
    return {
        type: REQUEST_CASHFLOW
    };
}

export function receive(data) {
    return {
        type: RECEIVE_CASHFLOW,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_CASHFLOW,
        errors: data
    }
}

export function getCashflow(data = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().cashflow, !force)) {
            dispatch(request())
            return axios({
                method: 'POST',
                url: fetchUrl + 'cashflow',
                data
            }).then(res => {
                dispatch(receive(res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}
