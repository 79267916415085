import {
    REQUEST_ITEM,
    RECEIVE_ITEM,
    UPDATE_ITEM,
    ERROR_ITEM
} from '../types'
import axios from "axios";
import {fetchUrl} from "../../constants/constants"

export function requestItem(key = '') {
    return {
        type: REQUEST_ITEM,
        payload: key
    };
}

export function receiveItem(data, message = '') {
    return {
        type: RECEIVE_ITEM,
        payload: data,
        message: message
    }
}

export function failedItem(data) {
    return {
        type: ERROR_ITEM,
        payload: data
    }
}

export function updateItem(data, updateType){
    return{
        type: UPDATE_ITEM,
        payload: data,
        updateType
    }
}

export function getLoginLog(id, params) {
    return dispatch => (
        axios({
            method: 'GET',
            url: fetchUrl + 'users/login-log/' + (id || 0),
            params
        }).then(res => {
            dispatch(updateItem(res.data.data, 'loginLog'))
            return true
        }).catch(err => {
            return false
        })
    )
}

export function getPilotFlights(id, params) {
    return dispatch => (
        axios({
            method: 'GET',
            url: fetchUrl + 'pilots/flights/' + (id || 0),
            params
        }).then(res => {
            dispatch(updateItem(res.data.data, 'pilotFlights'))
            return true
        }).catch(err => {
            return false
        })
    )
}

export function getDroneFlights(id, params) {
    return dispatch => (
        axios({
            method: 'GET',
            url: fetchUrl + 'drones/flights/' + (id || 0),
            params
        }).then(res => {
            dispatch(updateItem(res.data.data, 'droneFlights'))
            return true
        }).catch(err => {
            return false
        })
    )
}

export function getSupplierProperty(id, params) {
    return dispatch => (
        axios({
            method: 'GET',
            url: fetchUrl + 'suppliers/property/' + (id || 0),
            params
        }).then(res => {
            dispatch(updateItem(res.data.data, 'supplierProperty'))
            return true
        }).catch(err => {
            return false
        })
    )
}

export function getSupplierExpenses(id, params) {
    return dispatch => (
        axios({
            method: 'GET',
            url: fetchUrl + 'suppliers/expenses/' + (id || 0),
            params
        }).then(res => {
            dispatch(updateItem(res.data.data, 'supplierExpenses'))
            return true
        }).catch(err => {
            return false
        })
    )
}

export function getOrderExpenses(id, params) {
    return dispatch => (
        axios({
            method: 'GET',
            url: fetchUrl + 'orders/expenses/' + (id || 0),
            params
        }).then(res => {
            dispatch(updateItem(res.data.data, 'orderExpenses'))
            return true
        }).catch(err => {
            return false
        })
    )
}

export function getCustomerOrders(id, params) {
    return dispatch => (
        axios({
            method: 'GET',
            url: fetchUrl + 'customers/orders/' + (id || 0),
            params
        }).then(res => {
            dispatch(updateItem(res.data.data, 'customerOrders'))
            return true
        }).catch(err => {
            return false
        })
    )
}

export function getCustomerInvoices(id, params) {
    return dispatch => (
        axios({
            method: 'GET',
            url: fetchUrl + 'customers/invoices/' + (id || 0),
            params
        }).then(res => {
            dispatch(updateItem(res.data.data, 'customerInvoices'))
            return true
        }).catch(err => {
            return false
        })
    )
}

export function getOrderInvoices(id, params) {
    return dispatch => (
        axios({
            method: 'GET',
            url: fetchUrl + 'orders/invoices/' + (id || 0),
            params
        }).then(res => {
            dispatch(updateItem(res.data.data, 'orderInvoices'))
            return true
        }).catch(err => {
            return false
        })
    )
}

export function getCustomerDocuments(id, params) {
    return dispatch => (
        axios({
            method: 'GET',
            url: fetchUrl + 'customers/documents/' + (id || 0),
            params
        }).then(res => {
            dispatch(updateItem(res.data.data, 'customerDocuments'))
            return true
        }).catch(err => {
            return false
        })
    )
}

export function getCustomerPdfs(id, params) {
    return dispatch => (
        axios({
            method: 'GET',
            url: fetchUrl + 'customers/pdf-documents/' + (id || 0),
            params
        }).then(res => {
            dispatch(updateItem(res.data.data, 'customerPdfDocuments'))
            return true
        }).catch(err => {
            return false
        })
    )
}

export function getDocumentPdfs(id, params) {
    return dispatch => (
        axios({
            method: 'GET',
            url: fetchUrl + 'documents/pdf-documents/' + (id || 0),
            params
        }).then(res => {
            dispatch(updateItem(res.data.data, 'documentPdfDocuments'))
            return true
        }).catch(err => {
            return false
        })
    )
}

export function getOrderPdfs(id, params) {
    return dispatch => (
        axios({
            method: 'GET',
            url: fetchUrl + 'orders/pdf-documents/' + (id || 0),
            params
        }).then(res => {
            dispatch(updateItem(res.data.data, 'orderPdfDocuments'))
            return true
        }).catch(err => {
            return false
        })
    )
}

export function getOrderDocuments(id, params) {
    return dispatch => (
        axios({
            method: 'GET',
            url: fetchUrl + 'orders/documents/' + (id || 0),
            params
        }).then(res => {
            dispatch(updateItem(res.data.data, 'orderDocuments'))
            return true
        }).catch(err => {
            return false
        })
    )
}

export function getRegularInvoiceInvoices(id, params) {
    return dispatch => (
        axios({
            method: 'GET',
            url: fetchUrl + 'regular-invoices/invoices/' + (id || 0),
            params
        }).then(res => {
            dispatch(updateItem(res.data.data, 'regularInvoiceInvoices'))
            return true
        }).catch(err => {
            return false
        })
    )
}
