import React from "react"
import {Badge} from "react-bootstrap"
import IntlMessages from "../../helpers/IntlMessages"

export default (props) => {
    const {field, data} = props
    let itm = null
    let variant = 'primary'

    if(typeof field.ifCond !== "undefined"){
        let filled = false
        if(field.ifCond == data){
            itm = <Badge pill variant={field.ifVariant || variant}>{field.ifVal}</Badge>
            filled = true
        }else if(typeof field.ifCond == 'object'){
            field.ifCond.map((i, idx) => {
                if(i === data){
                    itm = <Badge pill variant={field.ifVariant[idx] || variant}>{field.ifVal[idx]}</Badge>
                    filled = true
                }
            })
        }
        if(typeof field.elseVal !== "undefined" && !filled){
            itm = <Badge pill variant={field.elseVariant || field.variant || variant}>{field.elseVal}</Badge>
        }
    }else{
        let s = null
        if(field.localizedPrefix){
            s = <IntlMessages id={`${field.localizedPrefix}.${data}`}/>
            if(field.variants){
                variant = field.variants[data]
            }
        }else{
            s = data
            if(field.variants){
                variant = field.variants[data]
            }
        }
        if(field.variant){ // force override variant
            variant = field.variant
        }
        itm = <Badge pill variant={variant} className={props.className}>{s}</Badge>
    }

    return itm
}