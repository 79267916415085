import React, {useState} from "react"
import {EyeIcon} from "../svg"
import {Button} from "react-bootstrap"

export default (props) => {
    const [visible, setVisible] = useState(false)
    const pass = visible ? props.password : '************'
    return(
        <span className="d-flex align-items-center" onMouseDown={props.viewable ? () => setVisible(true) : () => void(0)} onMouseLeave={() => setVisible(false)}>
            <span className="pt-1 mr-2">{pass}</span>
            <Button className="btn-empty icon-button small-icon" variant="light">
                <EyeIcon/>
            </Button>
        </span>
    )
}