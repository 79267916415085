import React from "react";

export default () => {
    return (
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <g xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M476,64H314.96L298.83,35.77a48,48,0,0,0-85.66,0L197.04,64H36A28.031,28.031,0,0,0,8,92V396a28.031,28.031,0,0,0,28,28H189.39l-11.42,40H144a8,8,0,0,0-8,8v24a8,8,0,0,0,8,8H368a8,8,0,0,0,8-8V472a8,8,0,0,0-8-8H334.03l-11.42-40H476a28.031,28.031,0,0,0,28-28V92A28.031,28.031,0,0,0,476,64ZM227.17,43.52l.21-.39a32,32,0,0,1,57.24,0l.21.39L420.99,281.88A32,32,0,0,1,392.28,328H119.72a32,32,0,0,1-28.71-46.12ZM360,480v8H152v-8ZM194.61,464l11.42-40h99.94l11.42,40ZM488,396a12.01,12.01,0,0,1-12,12H36a12.01,12.01,0,0,1-12-12V376H488Zm0-36H24V92A12.01,12.01,0,0,1,36,80H187.9L77,274.14a3.716,3.716,0,0,0-.21.39A48,48,0,0,0,119.72,344H392.28a48,48,0,0,0,42.93-69.47,3.716,3.716,0,0,0-.21-.39L324.1,80H476a12.01,12.01,0,0,1,12,12Z"/>
                <path
                    d="M119.792,304H392.208a8,8,0,0,0,6.946-11.969L263.552,54.657l-.4-.792a8,8,0,0,0-14.31,0l-.4.792-135.6,237.374A8,8,0,0,0,119.792,304ZM256,73.695,378.425,288H133.575Z"/>
                <path
                    d="M272,128H240a8,8,0,0,0-8,8v80a8,8,0,0,0,8,8h32a8,8,0,0,0,8-8V136A8,8,0,0,0,272,128Zm-8,80H248V144h16Z"/>
                <path
                    d="M272,232H240a8,8,0,0,0-8,8v32a8,8,0,0,0,8,8h32a8,8,0,0,0,8-8V240A8,8,0,0,0,272,232Zm-8,32H248V248h16Z"/>
            </g>
        </svg>
    )
}
