import _get from 'lodash/get'


export default (params, state, dataType) => {
    const filter = _get(state, `filters.${dataType}`, {filter: {}})
    delete filter.filter.clear
    delete filter.filter.__stayOpen

    Object.keys(filter).map(key => {
        if(filter[key] === "" || filter[key] === null){
            delete filter[key]
        }
    })

    return {...params, ...filter.filter}
}