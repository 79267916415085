import React from "react"
import Fa from "../components/Fa"

export default [
    {
        id: "dashboard",
        icon: <Fa n="chart-network"/>,
        label: "menu.dashboard",
    },{
        id: "evidence",
        icon: <Fa n="folder-open"/>,
        label: "menu.evidence",
        subs: [
            {
                id: "evidence_passwords",
                icon: <Fa n="key"/>,
                label: "menu.passwords",
            },{
                id: "evidence_property",
                icon: <Fa n="car-building"/>,
                label: "menu.property",
            },{
                id: "evidence_orders",
                icon: <Fa n="plus"/>,
                label: "menu.orders",
            },{
                id: "evidence_offers",
                icon: <Fa n="right-left"/>,
                label: "menu.offers",
            },{
                id: "evidence_customers",
                icon: <Fa n="user-tie"/>,
                label: "menu.customers",
            },{
                id: "evidence_suppliers",
                icon: <Fa n="shopping-cart"/>,
                label: "menu.suppliers",
            },
        ]
    },{
        id: "finance",
        icon: <Fa n="dollar-sign"/>,
        label: "menu.finance",
        subs: [
            {
                id: "finance_bank",
                icon: <Fa n="building-columns"/>,
                label: "menu.bank",
            },{
                id: "finance_internal_bank_transfers",
                icon: <Fa n="money-bill-transfer"/>,
                label: "menu.internal-bank-transfers",
            },{
                id: "finance_commissions",
                icon: <Fa n="hand-holding-dollar"/>,
                label: "menu.commissions",
            },{
                id: "finance_invoices",
                icon: <Fa n="file-invoice-dollar"/>,
                label: "menu.invoices",
            },{
                id: "finance_regular_invoices",
                icon: <Fa n="clock"/>,
                label: "menu.regular-invoices",
            },{
                id: "finance_expenses",
                icon: <Fa n="receipt"/>,
                label: "menu.expenses",
            },{
                id: "finance_regular_expenses",
                icon: <Fa n="clock"/>,
                label: "menu.regular-expenses",
            },{
                id: "finance_cashflow",
                icon: <Fa n="lightbulb-dollar"/>,
                label: "menu.cashflow",
            },{
                id: "finance_bank_transactions",
                icon: <Fa n="credit-card"/>,
                label: "menu.bank-transactions",
            },{
                id: "finance_taxes",
                icon: <Fa n="percent"/>,
                label: "menu.taxes",
            },
        ]
    },{
        id: "document",
        icon: <Fa n="newspaper"/>,
        label: "menu.document",
        subs: [
            {
                id: "document_pdfs",
                icon: <Fa n="file-pdf"/>,
                label: "menu.pdfs",
            },{
                id: "document_documents",
                icon: <Fa n="newspaper"/>,
                label: "menu.documents",
            },{
                id: "document_doc_categories",
                icon: <Fa n="folder-tree"/>,
                label: "menu.doc-categories",
            },{
                id: "document_templates",
                icon: <Fa n="file"/>,
                label: "menu.document-templates",
            },{
                id: "document_bank_statements",
                icon: <Fa n="file-invoice-dollar"/>,
                label: "menu.bank-statements",
            }
        ]
    },{
        id: "events",
        icon: <Fa n="calendar-days"/>,
        label: "menu.events",
    },{
        id: "news",
        icon: <Fa n="message-dots"/>,
        label: "menu.news",
    },{
        id: "drone",
        icon: <Fa n="drone"/>,
        label: "menu.drone",
        subs: [
            {
                id: "drone_flights",
                icon: <Fa n="book"/>,
                label: "menu.drone-pilot-diary",
            },
            {
                id: "drone_maintenance",
                icon: <Fa n="gear"/>,
                label: "menu.drone-maintenance",
            },
            {
                id: "drone_places",
                icon: <Fa n="map-pin"/>,
                label: "menu.drone-places",
            },
            {
                id: "drone_pilots",
                icon: <Fa n="gamepad-modern"/>,
                label: "menu.drone-pilots",
            },
            {
                id: "drone_drones",
                icon: <Fa n="drone"/>,
                label: "menu.drone-drones",
            },
        ]
    }, {
        id: "admin",
        icon: <Fa n="shield-halved"/>,
        label: "menu.admin",
        subs: [
            {
                id: "admin_users",
                icon: <Fa n="users"/>,
                label: "menu.users",
            }, {
                id: "admin_permissions",
                icon: <Fa n="key"/>,
                label: "menu.permissions",
            }, {
                id: "admin_roles",
                icon: <Fa n="shield-halved"/>,
                label: "menu.roles",
            }, {
                id: "admin_strings",
                icon: <Fa n="text-size"/>,
                label: "menu.strings",
            }
        ]
    },
]