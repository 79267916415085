import React from "react"
import {
    Route,
    Redirect
} from 'react-router-dom'
import {routes} from "./constants/constants"

export default ({ component: Component, auth, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                auth.isAuthenticated ? (
                    <Redirect
                        to={{
                            pathname: routes.dashboard,
                            state: { from: props.location }
                        }}
                    />
                ) : (
                    <Component {...props} />
                )
            }
        />
    )
}