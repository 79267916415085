import {
  REQUEST_INFO,
  RECEIVE_INFO,
  ERROR_INFO
} from '../types'
import {fetchUrl} from '../../constants/constants'
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import axios from 'axios'

function request() {
  return {
    type: REQUEST_INFO
  };
}

export function receive(data, message = '') {
  return {
    type: RECEIVE_INFO,
    payload: data,
    message: message
  }
}

function failed(data) {
  return {
    type: ERROR_INFO,
    errors: data
  }
}

export function getInfo() {
  return dispatch => {
    dispatch(request())
    return axios({
      method: 'GET',
      url: fetchUrl + 'info'
    }).then(res => {
      dispatch(receive(res.data.data))
    }).catch(err => {
      const errors = fetchErrorCtrl(err)
      dispatch(failed(errors))
    })
  }
}