export const fetchUrl = process.env.NODE_ENV === 'production' ? '/api/' : 'http://system.dreambee.cz.local/api/'
export const mediaUrl = process.env.NODE_ENV === 'production' ? '/media/' : 'http://system.dreambee.cz.local/api/'
export const fileUrl = process.env.NODE_ENV === 'production' ? '/files/' : 'http://system.dreambee.cz.local/api/'

export const routes = {
    landing: '/dashboard',

    login: '/login',
    changePassword: '/change-password',
    forgottenPassword: '/forgotten-password',
    setPassword: '/set-password',
    userProfile: '/profile',
    error: '/error',
    document_signature: '/sign-document',

    dashboard: '/dashboard',

    evidence: '/evidence',
    evidence_passwords: '/evidence/passwords',
    evidence_property: '/evidence/property',
    evidence_suppliers: '/evidence/suppliers',
    evidence_customers: '/evidence/customers',
    evidence_orders: '/evidence/orders',
    evidence_offers: '/evidence/offers',

    finance: '/finance',
    finance_bank: '/finance/bank',
    finance_internal_bank_transfers: '/finance/internal-bank-transfers',
    finance_commissions: '/finance/commissions',
    finance_expenses: '/finance/expenses',
    finance_regular_expenses: '/finance/regular-expenses',
    finance_invoices: '/finance/invoices',
    finance_regular_invoices: '/finance/regular-invoices',
    finance_taxes: '/finance/tax-entries',
    finance_bank_transactions: '/finance/bank-transactions',
    finance_cashflow: '/finance/cashflow',

    document: '/document',
    document_pdfs: '/document/pdf-documents',
    document_documents: '/document/documents',
    document_doc_categories: '/document/doc-categories',
    document_bank_statements: '/document/bank-statements',
    document_templates: '/document/templates',

    drone: '/drone',
    drone_flights: '/drone/flights',
    drone_maintenance: '/drone/maintenance-diary',
    drone_places: '/drone/places',
    drone_pilots: '/drone/pilots',
    drone_drones: '/drone/drones',

    admin: '/admin',
    admin_dashboard: '/admin/users',
    admin_users: '/admin/users',
    admin_roles: '/admin/roles',
    admin_permissions: '/admin/permissions',
    admin_strings: '/admin/strings',

    news: '/news',
    events: '/events',
}

export const routePermissions = {
    dashboard: {
        permissions: [],
    },

    evidence: {
        permissions: ['password.view', 'property.view', 'supplier.view', 'customer.view', 'order.view', 'offer']
    },
    evidence_passwords: {
        permissions: ['password.view'],
    },
    evidence_property: {
        permissions: ['property.view']
    },
    evidence_suppliers: {
        permissions: ['supplier.view']
    },
    evidence_customers: {
        permissions: ['customer.view']
    },
    evidence_orders: {
        permissions: ['order.view']
    },
    evidence_offers: {
        permissions: ['offer.view']
    },

    finance: {
        permissions: ['bank.view', 'expense.view', 'invoice.view', 'tax-entry.view', 'bank-transaction.view', 'internal-bank-transfer.view', 'commission.view']
    },
    finance_bank: {
        permissions: ['bank.view'],
    },
    finance_internal_bank_transfers: {
        permissions: ['internal-bank-transfer.view'],
    },
    finance_commissions: {
        permissions: ['commission.view'],
    },
    finance_expenses: {
        permissions: ['expense.view']
    },
    finance_regular_expenses: {
        permissions: ['regular-expense.view']
    },
    finance_invoices: {
        permissions: ['invoice.view']
    },
    finance_regular_invoices: {
        permissions: ['invoice.view']
    },
    finance_taxes: {
        permissions: ['tax-entry.view']
    },
    finance_bank_transactions: {
        permissions: ['bank-transaction.view']
    },
    finance_cashflow: {
        permissions: ['cashflow.view']
    },

    document: {
        permissions: ['pdf-document.view', 'pdf-category.view', 'bank-statement.view']
    },
    document_pdfs: {
        permissions: ['pdf-document.view']
    },
    document_documents: {
        permissions: ['document.view']
    },
    document_doc_categories: {
        permissions: ['doc-category.view']
    },
    document_bank_statements: {
        permissions: ['bank-statement.view']
    },
    document_templates: {
        permissions: ['document-template.view']
    },

    drone: {
        permissions: ['flight.view', 'drone-maintenance.view', 'place.view', 'pilot.view', 'drone.view']
    },
    drone_flights: {
        permissions: ['flight.view'],
    },
    drone_maintenance: {
        permissions: ['drone-maintenance.view'],
    },
    drone_places: {
        permissions: ['place.view'],
    },
    drone_pilots: {
        permissions: ['pilot.view'],
    },
    drone_drones: {
        permissions: ['drone.view'],
    },

    admin: {
        permissions: ['user.view', 'role.view', 'permission.view', 'admin-string.view']
    },
    admin_users: {
        permissions: ['user.view']
    },
    admin_roles: {
        permissions: ['role.view']
    },
    admin_permissions: {
        permissions: ['permission.view']
    },
    admin_strings: {
        permissions: ['admin-string.view']
    },

    news: {
        permissions: ['admin-news.view']
    },

    events: {
        permissions: ['admin-event.view']
    },

    user: {
        permissions: []
    },
    userProfile: {
        permissions: []
    },
}

export const enumsFetchRoutes = {
    stringCategories: '/string-categories',
    users: '/users',
    permissions: '/permissions',
    permActions: '/permission-actions',
    roles: '/roles',
    places: '/places',
    pilots: '/pilots',
    drones: '/drones',
    droneActivities: '/drone-activities',
    property: '/property',
    suppliers: '/suppliers',
    customers: '/customers',
    customers_simple: '/customers-simple',
    customersAndSuppliers: '/customers-and-suppliers',
    bankEntriesTypes: '/bank-entries-types',
    docCategories: '/doc-categories',
    expenses: '/expenses',
    bankTransactions: '/bank-transactions',
    bankTransactionsPersons: '/bank-transactions-persons',
    invoices: '/invoices',
    documentTemplates: '/document-templates',
    orders: '/orders',
    offers: '/offers',
    warehouse: '/warehouse',
    warehouseComponents: '/warehouse-components',
    iqTariffs: '/iq-tariffs',
}

export const toasterDuration = 2500

export const specializationColors = {
    '0': 'primary',
    '1': 'blue',
    '2': 'green'
}

export const invoiceTypeColors = {
    '0': 'secondary',
    '1': 'info',
    '2': 'primary',
    '3': 'primary'
}

export const offerStatusColors = {
    '0': 'primary',
    '1': 'info',
    '2': 'warning',
    '3': 'success',
    '4': 'danger',
}

export const listRefresh = {
    default: 1000 * 60 * 2,
}