import {
    REQUEST_PDF_DOCUMENTS,
    RECEIVE_PDF_DOCUMENTS,
    UPDATE_PDF_DOCUMENTS,
    ERROR_PDF_DOCUMENTS
} from '../types'
import {fetchUrl} from '../../constants/constants'
import {requestItem, receiveItem, failedItem, getEnums, reloadDashboard} from "../actions"
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'
import prepareFilters from "../../helpers/prepareFilters";

function request(category) {
    return {
        type: REQUEST_PDF_DOCUMENTS,
        category
    };
}

export function receive(data, category) {
    return {
        type: RECEIVE_PDF_DOCUMENTS,
        payload: data,
        category
    }
}

function failed(data, category) {
    return {
        type: ERROR_PDF_DOCUMENTS,
        payload: data,
        category
    }
}

function update(data, id, updateType) {
    return {
        type: UPDATE_PDF_DOCUMENTS,
        id,
        payload: data,
        updateType,
    }
}

export function getPdfDocuments(params = {}, force = false) {
    return (dispatch, getState) => {
        const category = params.category || 0
        const pdfDocuments = getState().pdfDocuments
        if (shouldFetch(pdfDocuments[category] || pdfDocuments, !force)) {
            dispatch(request(category))
            return axios({
                method: 'GET',
                url: fetchUrl + 'pdf-documents',
                params: prepareFilters(params, getState(), "pdfDocuments")
            }).then(res => {
                dispatch(receive(res.data.data, category))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors), category)
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function getPdfDocument(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('pdfDocument'))
            return axios({
                method: 'GET',
                url: fetchUrl + 'pdf-documents/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data))
                dispatch(reloadDashboard())
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function createPdfDocument(data) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'POST',
                url: fetchUrl + 'pdf-documents',
                data
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_created'))
                dispatch(update(res.data.data, false, 'create'))
                dispatch(getEnums('docCategories', {}, true));
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function updatePdfDocument(data, id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('pdfDocument'))
            return axios({
                method: 'PUT',
                url: fetchUrl + 'pdf-documents/' + id,
                data
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                dispatch(getEnums('docCategories', {}, true));

                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function deletePdfDocument(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'DELETE',
                url: fetchUrl + 'pdf-documents/' + id
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_deleted'))
                dispatch(update(id, 'delete'))
                dispatch(getEnums('docCategories', {}, true));
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}
