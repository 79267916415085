import {
    REQUEST_DOCUMENTS,
    RECEIVE_DOCUMENTS,
    UPDATE_DOCUMENTS,
    ERROR_DOCUMENTS
} from '../types'
import {fetchUrl} from '../../constants/constants'
import {requestItem, receiveItem, failedItem, getEnums, updateItem, reloadDashboard} from "../actions"
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'
import prepareFilters from "../../helpers/prepareFilters";

function request(category) {
    return {
        type: REQUEST_DOCUMENTS,
        category
    };
}

export function receive(data, category) {
    return {
        type: RECEIVE_DOCUMENTS,
        payload: data,
        category
    }
}

function failed(data, category) {
    return {
        type: ERROR_DOCUMENTS,
        payload: data,
        category
    }
}

function update(data, id, updateType) {
    return {
        type: UPDATE_DOCUMENTS,
        id,
        payload: data,
        updateType,
    }
}

export function getDocuments(params = {}, force = false) {
    return (dispatch, getState) => {
        const category = params.category || 0
        const documents = getState().documents
        if (shouldFetch(documents[category] || documents, !force)) {
            dispatch(request(category))
            return axios({
                method: 'GET',
                url: fetchUrl + 'documents',
                params: prepareFilters(params, getState(), "documents")
            }).then(res => {
                dispatch(receive(res.data.data, category))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors), category)
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function getDocument(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('document'))
            return axios({
                method: 'GET',
                url: fetchUrl + 'documents/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function createDocument(data) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'POST',
                url: fetchUrl + 'documents',
                data
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_created'))
                dispatch(update(res.data.data, false, 'create'))
                dispatch(getEnums('docCategories', {}, true));
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function updateDocument(data, id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('document'))
            return axios({
                method: 'PUT',
                url: fetchUrl + 'documents/' + id,
                data
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                dispatch(getEnums('docCategories', {}, true));
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function unlockDocument(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('document'))
            return axios({
                method: 'PATCH',
                url: fetchUrl + 'documents/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_unlocked'))
                dispatch(update(res.data.data, id, 'update'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function deleteDocument(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'DELETE',
                url: fetchUrl + 'documents/' + id
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_deleted'))
                dispatch(update(id, 'delete'))
                dispatch(getEnums('docCategories', {}, true));
                dispatch(reloadDashboard())
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function sendDocument(data, id) {
    return (dispatch, getState) => {
        return axios({
            method: 'POST',
            url: fetchUrl + 'documents/email/' + id,
            data
        }).then(res => {
            dispatch(updateItem(res.data.data, 'documentSentLog'))
            return true
        }).catch(err => {
            return false
        })
    }
}

export function sendDocumentSignature(data, id) {
    return (dispatch, getState) => {
        return axios({
            method: 'POST',
            url: fetchUrl + 'documents/email-sign/' + id,
            data
        }).then(res => {
            dispatch(updateItem(res.data.data, 'documentSentLog'))
            dispatch(reloadDashboard())
            return true
        }).catch(err => {
            return false
        })
    }
}
