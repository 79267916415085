import React from "react"
import IntlMessages from "./IntlMessages"

export default (localizedPrefix, label) =>{
    if(!localizedPrefix){
        return label
    }else{
        return <IntlMessages id={`${localizedPrefix}.${label}`} />
    }
    const labels_ = label.split('.')
    const message = []
    return labels_.map((l, i) => {
        return <>
            <IntlMessages id={`${localizedPrefix}.${l}`} key={i} />{(labels_.length > 1 && i < labels_.length - 1) && " - "}
            </>
    })
    return message
}