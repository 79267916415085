import React from "react"
import IntlMessages from "../../helpers/IntlMessages";

export default (props) => {
    return (
        <div className="iframe-container">
            {
                props.hideNewTabLink ? null : (
                    <div className="text-right position-absolute w-100">
                        <a href={props.url} target="_blank"><IntlMessages id="general.show-in-new-window"/></a>
                    </div>
                )
            }
            <iframe src={`${props.url}#toolbar=0&navpanes=0`} frameBorder="0"/>
        </div>
    )
}