import {
    REQUEST_BANK_ENTRIES,
    RECEIVE_BANK_ENTRIES,
    ERROR_BANK_ENTRIES
} from '../types'
import {fetchUrl} from '../../constants/constants'
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'
import prepareFilters from "../../helpers/prepareFilters"

function request() {
    return {
        type: REQUEST_BANK_ENTRIES
    };
}

export function receive(data) {
    return {
        type: RECEIVE_BANK_ENTRIES,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_BANK_ENTRIES,
        errors: data
    }
}

export function getBankEntries(params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().bankEntries, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'bank',
                params: prepareFilters(params, getState(), "banks")
            }).then(res => {
                dispatch(receive(res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}