import React, {useState, useEffect} from "react"
import {connect} from 'react-redux'
import {Toast} from "react-bootstrap"
import IntlMessages from "../helpers/IntlMessages"
import findIndex from 'lodash/findIndex'
import {toasterDuration} from '../constants/constants'
import _isEmpty from 'lodash/isEmpty'

const Toaster = (props) => {
    const {toaster, item} = props
    const [toasts, setToasts] = useState([])

    const hide = (index) => {
        const current = [...toasts]
        current.splice(index, 1)
        setToasts(current)
    }

    const addToast = (data) => {
        const current = [...toasts]
        if (findIndex(toasts, (t => t.message === data.message)) < 0) {
            current.push(data)
            setToasts(current)
        }
    }

    useEffect(() => {
            if (toaster.message) {
                addToast(toaster)
            }
        }, [toaster.counter]
    )

    useEffect(() => {
            if (item.message) {
                const isError = !_isEmpty(item.errors)
                addToast({
                    toasterType: isError ? "ERROR" : "OK",
                    title: isError ? 'general.error' : 'general.success',
                    message: item.message
                })
            }
        }, [item.message]
    )

    return (
        <div className="toast-wrapper">
            {
                toasts.map((item, index) => (
                    <Toast onClose={() => hide(index)} delay={toasterDuration} autohide key={index}
                           className={item.toasterType.toLowerCase()}>
                        <Toast.Header>
                            <strong className="mr-auto">
                                <IntlMessages id={item.title}/>
                            </strong>
                        </Toast.Header>
                        <Toast.Body>
                            <IntlMessages id={item.message}/>
                        </Toast.Body>
                    </Toast>
                ))
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        toaster: state.toaster,
        item: state.item
    }
}

export default connect(
    mapStateToProps,
    null
)(Toaster)