import {
    REQUEST_INTERNAL_BANK_TRANSFERS,
    RECEIVE_INTERNAL_BANK_TRANSFERS,
    UPDATE_INTERNAL_BANK_TRANSFERS,
    ERROR_INTERNAL_BANK_TRANSFERS
} from '../types'
import {fetchUrl} from '../../constants/constants'
import {requestItem, receiveItem, failedItem, reloadDashboard} from "../actions"
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'
import prepareFilters from "../../helpers/prepareFilters";

function request() {
    return {
        type: REQUEST_INTERNAL_BANK_TRANSFERS
    };
}

export function receive(data) {
    return {
        type: RECEIVE_INTERNAL_BANK_TRANSFERS,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_INTERNAL_BANK_TRANSFERS,
        errors: data
    }
}

function update(data, id, updateType) {
    return {
        type: UPDATE_INTERNAL_BANK_TRANSFERS,
        id,
        payload: data,
        updateType
    }
}

export function getInternalBankTransfers(params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().internalBankTransfers, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'internal-bank-transfers',
                params: prepareFilters(params, getState(), "internalBankTransfers")
            }).then(res => {
                dispatch(receive(res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function getInternalBankTransfer(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('internalBankTransfer'))
            return axios({
                method: 'GET',
                url: fetchUrl + 'internal-bank-transfers/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function createInternalBankTransfer(data) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'POST',
                url: fetchUrl + 'internal-bank-transfers',
                data
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_created'))
                dispatch(update(res.data.data, false, 'create'))
                dispatch(reloadDashboard())
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function updateInternalBankTransfer(data, id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('internalBankTransfer'))
            return axios({
                method: 'PUT',
                url: fetchUrl + 'internal-bank-transfers/' + id,
                data
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                dispatch(reloadDashboard())
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function deleteInternalBankTransfer(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'DELETE',
                url: fetchUrl + 'internal-bank-transfers/' + id
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_deleted'))
                dispatch(update(res.data.data, id, 'delete'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}