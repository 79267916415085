import React, {useEffect} from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom"
import isArray from 'lodash/isArray'
import {bindActionCreators} from "redux"
import {getEnums} from "../../redux/actions"
import getLocalizedEnumMessage from "../../helpers/getLocalizedEnumMessage"
import Badge from "./Badge"
import DotSpinner from "./DotSpinner"

const ItemEnums = (props) => {
    const {enums, data, field} = props
    const enumType = field.enumType
    useEffect(() => {
        if (!enums) {
            props.actions.getEnums(enumType, field.enumParams || {})
        }
    }, [enums])

    if (!enums || enums.isFetching) {
        return <DotSpinner/>
    }

    const edata = isArray(data) ? data : [data]

    return edata.map((d, i) => {
        const item_exists = enums.data[d] || false
        switch (field.as) {
            case 'badge':
                return <Badge key={i} field={field} data={item_exists ? enums.data[d].label : ' ! '} className='mr-1 my-1'/>
            default:
                const str = enums.data[d] ? getLocalizedEnumMessage(field.localizedPrefix, enums.data[d].label) : ' ! '
                return field.to && item_exists ? <Link to={`${field.to}/${enums.data[d].value}`} key={i}>{str}</Link> : <span key={i} className={field.className}>{str}</span>
        }
    })
}

function mapStateToProps(state, ownProps) {
    return {
        enums: state.enums[ownProps.field.enumType] || false
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getEnums
        }, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ItemEnums)