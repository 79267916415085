import {
    REQUEST_DOCUMENTS,
    RECEIVE_DOCUMENTS,
    UPDATE_DOCUMENTS,
    ERROR_DOCUMENTS
} from '../types'
import {
    initialPdfDocumentsFetchState,
    initialFetchState,
} from '../../constants/defaultValues'
import {listRefresh} from "../../constants/constants"
import _get from "lodash/get"

export default (state = initialPdfDocumentsFetchState, action = {}) => {
    let newState = {...state}
    switch(action.type) {
        case REQUEST_DOCUMENTS:
            newState[action.category] = {
                ...initialFetchState,
                data: _get(newState, [action.category, 'data'], {}),
                isFetching: true,
            }
            return newState
        case RECEIVE_DOCUMENTS:
            newState[action.category] = {
                ...newState[action.category],
                isFetching: false,
                data: action.payload,
                shouldReload: false,
                validUntil: listRefresh.default + Date.now(),
            }
            return newState
        case UPDATE_DOCUMENTS:
            let index = null
            let category = null
            let category_secondary = null
            switch(action.updateType) {
                case 'create':
                    category = action.payload.category
                    category_secondary = action.payload.category_secondary
                    if(newState[category]){
                        newState[category].data.items.unshift(action.payload)
                    }
                    if(category_secondary && newState[category_secondary]){
                        newState[category_secondary].data.items.unshift(action.payload)
                    }
                    break
                case 'update':
                    category = action.payload.category
                    category_secondary = action.payload.category_secondary

                    Object.keys(newState).map(cat => {
                        index = newState[cat].data.items.findIndex(item => item.id == action.id)
                        if(index > -1){
                            newState[cat].data.items.splice(index, 1)
                        }
                    })

                    if(newState[category]){
                        newState[category].data.items.unshift(action.payload)
                    }
                    if(category_secondary && newState[category_secondary]){
                        newState[category_secondary].data.items.unshift(action.payload)
                    }
                    break
                case 'delete':
                    Object.keys(newState).map(cat => {
                        index = newState[cat].data.items.findIndex(item => item.id == action.id)
                        if(index > -1){
                            newState[cat].data.items.splice(index, 1)
                        }
                    })
                    break
            }
            return newState
        case ERROR_DOCUMENTS:
            newState[action.category] = {
                ...initialFetchState,
                isFetching: true,
                errors: action.errors,
                message: action.errors.server || '',
            }
            return newState
        default:
            return state
    }
}