import React from "react"
import IntlMessages from "../../helpers/IntlMessages"
import {FormattedDate, FormattedTime, FormattedNumber, FormattedDateParts, FormattedPlural} from "react-intl"
import {Link, NavLink} from "react-router-dom"
import PasswordField from "./PasswordField"
import ItemEnums from "./ItemEnums"
import ItemPermissions from "./ItemPermissions"
import replaceSpaces from "../../helpers/replaceSpaces"
import replaceImgLink from "../../helpers/replaceImgLink"
import _isEmpty from "lodash/isEmpty"
import _isArray from "lodash/isArray"
import {OverlayTrigger, Popover} from "react-bootstrap"
import Badge from "./Badge"
import Icon from "./Icon"
import getMediaUrl from "../../helpers/getMediaUrl";
import IframePDF from "./IframePDF";

export const PossiblePopOver = props => {
    const maxLength = 1
    const {isListItem = false, value, className = "", popover, wrapParagraph} = props
    const pp = popover ? (_isEmpty(popover.popoverContent) ? popover : popover.popoverContent) : null

    if(isListItem){
        if(value.length > maxLength){
            return(
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    overlay={
                        <Popover>
                            {
                                props.label ? (
                                    <Popover.Title>
                                        <IntlMessages id={props.label.includes(".") ? props.label : `input.${props.label}`}/>
                                    </Popover.Title>
                                ) : null
                            }
                            <Popover.Content>
                                {
                                    pp ? (
                                        popover.html ? (
                                            <div dangerouslySetInnerHTML={{__html: pp}}/>
                                        ) : pp
                                    ) : value
                                }
                            </Popover.Content>
                        </Popover>
                    }
                >
                    {
                        wrapParagraph ? <p className={`${className} truncate mb-0 pr-3`}>{value}</p> : <span className="d-block truncate pr-3">{value}</span>
                    }
                </OverlayTrigger>
            )
        }else{
            return(
                <p className={`${className} truncate mb-0`}>
                    {value}
                </p>
            )
        }
    }else{
        return value || "--"
    }
}

function getItem(props) {
    const {type = "text", value = "", isListItem = false} = props
    switch (type) {
        case 'text':
        case 'textarea':
            return <PossiblePopOver {...props}/>
        case 'wysiwyg':
        case 'wysiwyg-document':
            return (
                <div
                    className={`wysiwyg-preview ${type == 'wysiwyg-document' ? 'wysiwyg-document-preview' : ''} ${props.fontFamily == 'Saira' ? 'font-saira' : ''}`}>
                    {
                        _isEmpty(value) ? "--" : (
                            <div dangerouslySetInnerHTML={{__html: replaceImgLink(replaceSpaces(value))}}/>
                        )
                    }
                </div>
            )
        case 'boolean':
            return(
                <>
                    {
                        isListItem ? (
                            <strong>
                                <IntlMessages id={`${props.localizedPrefix || 'input'}.${props.label}`}/>:{" "}
                            </strong>
                        ) : null
                    }
                    <IntlMessages id={value ? 'general.yes' : 'general.no'}/>
                </>
            )
        case 'percentage':
            return <span>{value} %</span>
        case 'enums':
            if(props.enumType === 'permissions'){
                return <ItemPermissions field={{...props}} permissions={value || []}/>
            }else{
                return <ItemEnums field={{...props}} data={ value === 0 ? 0 : (value || [])}/>
            }
        case 'link':
            return <NavLink to={props.to || value}>{value}</NavLink>
        case 'outlink':
            return <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>
        case 'outlinkPdf':
            return <a href={getMediaUrl(props.item.filename, "document")} target="_blank" rel="noopener noreferrer">{value}</a>
        case 'password':
            return <PasswordField password={value} viewable={props.viewable}/>
        case 'date':
            return value && value != "--" ? <FormattedDate value={value}/> : '--'
        case 'monthyear':
            return value ? <FormattedDate value={value} month="long" year="numeric"/> : '--'
        case 'time':
            return value ? <FormattedTime value={value}/> : '--'
        case 'month':
            return value ? <FormattedDateParts value={value} month="long">
                {parts => parts[0] ? parts[0].value : '--'}
            </FormattedDateParts> : '--'
        case 'price':
            const currency = props.currency || "CZK"
            const decimals = props.minimalDecimals ? (currency === 'CZK' ? 1 : 3) : false
            let colorClassName = ""
            if(props.colorValues){
                if((value || 0) < 0){
                    colorClassName = 'text-danger'
                }else{
                    colorClassName = 'text-success'
                }
            }
            return (
                <span className={colorClassName}>
                    <FormattedNumber value={value || 0}
                                 style="currency"
                                 minimumSignificantDigits={decimals === false ? undefined : decimals}
                                 currency={currency}/>
                </span>
            )
        case 'datetime':
            return value ? <><FormattedDate value={value}/>{" "}<FormattedTime value={value}/></> : '--'
        case 'number':
            return <FormattedNumber value={value || 0}/>
        case 'badge':
            const badges = _isArray(value) ? value : [value]
            return _isEmpty(badges) ? <IntlMessages id="general.empty"/> : (
                badges.map((item, index) => {
                    return <Badge key={index} variant={item.type || 'primary'} className="mb-1 mr-1" data={item} field={props}/>
                })
            )
        case 'icon':
            const icons = _isArray(value) ? value : [value]
            return _isEmpty(icons) ? <IntlMessages id="general.empty"/> : (
                icons.map((item, index) => {
                    return <Icon key={index} variant={item.type || 'primary'} className="mb-1 mr-1" data={item} field={props}/>
                })
            )
        case 'plural-string':
            return (
                <>
                    <strong className="mr-1">{value}</strong>
                    <FormattedPlural value={value}
                                     other={<IntlMessages id={`admin.${props.name}-count-other`}/>}
                                     one={<IntlMessages id={`admin.${props.name}-count-one`}/>}
                                     few={<IntlMessages id={`admin.${props.name}-count-feq`}/>}/>
                </>
            )
        case 'pdf':
            return value.map((f, i) => {
                return(
                    <div className="p-2">
                        <IframePDF key={i} url={getMediaUrl(f.filename,'document')}/>
                    </div>
                )
            })
        case 'raw':
            //const unit = props.unit ? ' ' + props.unit : ''
            return value// + unit
        default:
            return 'unknown type'
    }
}

export default (props) => {
    const isList = props.as === 'list' && _isArray(props.value) && props.value.length > 0
    const Elm = isList ? 'ul' : (props.elm || 'p')
    const MainElm = props.mainElm || props.view == "table" ? "td" : "div"
    const listClass = isList ? (props.listStyle ? 'p-0 pl-3 list-style-normal' : 'p-0 list-style-none') : ''
    const className = props.isListItem ? "mb-0 truncate" : `data-entry ${props.className || 'mb-3'}`
    if(props.as === 'raw'){
        return getItem(props)
    }
    const LabelElm = props.isListItem ? "label" : "span"
    const label = props.view == "table" ? null : props.label
    const p = {...props}

    const entry = (
        <Elm className={`${className} ${listClass}`}>
            {props.prefix} {getItem(p)} {props.unit} {props.suffix}
            {props.children}
        </Elm>
    )

    return(
        <MainElm className={props.isListItem ? props.className : (props.type == "badge" ? "d-inline-block" : "")} onClick={props.onClick}>
            {label && <LabelElm className="text-muted text-small mb-2"><IntlMessages showHint={false} id={label.includes('.') ? label : `input.${label}`}/></LabelElm>}
            {
                props.to && props.type != "enums" ? (
                    <Link to={props.to} className="link-underline" target={props.view == "table" && props.linkInNewTab !== false && "_blank"}>{entry}</Link>
                ) : entry
            }
        </MainElm>
    )
}