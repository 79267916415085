import {
    SET_TAB
} from '../types'

export default (state = {}, action) => {
    if (action.type === SET_TAB) {
        const newState = {...state}
        newState[action.page] = action.payload
        return newState
    } else {
        return state
    }
}