import {
    REQUEST_CASHFLOW,
    RECEIVE_CASHFLOW,
    ERROR_CASHFLOW
} from '../types'
import {initialFetchState} from '../../constants/defaultValues'

export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_CASHFLOW:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_CASHFLOW:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                errors: {},
                message: '',
            }
        case ERROR_CASHFLOW:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                message: action.errors.server || '',
            }
        default:
            return state
    }
}