import { combineReducers } from 'redux'
import toaster from './toaster/reducer'
import tabs from './tabs/reducer'
import auth from './auth/reducer'
import info from './info/reducer'
import strings from './strings/reducer'
import item from './item/reducer'
import sequence from './sequence/reducer'
import users from './users/reducer'
import roles from './roles/reducer'
import permissions from './permissions/reducer'
import adminStrings from './adminStrings/reducer'
import filters from './filters/reducer'
import {locale, colorTheme} from './settings/reducer'
import pdfDocuments from './pdfDocuments/reducer'
import documents from './documents/reducer'
import docCategories from './docCategories/reducer'
import menu from './menu/reducer'
import droneMaintenance from './droneMaintenance/reducer'
import drones from './drones/reducer'
import pilots from './pilots/reducer'
import places from './places/reducer'
import flights from './flights/reducer'
import passwords from './passwords/reducer'
import property from './property/reducer'
import suppliers from './suppliers/reducer'
import customers from './customers/reducer'
import orders from './orders/reducer'
import bankEntries from './bankEntries/reducer'
import bankTransactions from './bankTransactions/reducer'
import expenses from './expenses/reducer'
import invoices from './invoices/reducer'
import regularInvoices from './regularInvoices/reducer'
import regularExpenses from './regularExpenses/reducer'
import bankStatements from './bankStatements/reducer'
import taxEntries from './taxEntries/reducer'
import documentTemplates from './documentTemplates/reducer'
import dashboard from './dashboard/reducer'
import news from './news/reducer'
import internalBankTransfers from './internalBankTransfers/reducer'
import commissions from './commissions/reducer'
import cashflow from './cashflow/reducer'
import offers from './offers/reducer'
import events from './events/reducer'

import enums from './enums/reducer'

export default combineReducers({
  toaster,
  tabs,
  auth,
  info,
  strings,
  item,
  sequence,
  users,
  roles,
  permissions,
  enums,
  adminStrings,
  filters,
  locale,
  colorTheme,
  pdfDocuments,
  documents,
  docCategories,
  menu,
  passwords,
  droneMaintenance,
  drones,
  pilots,
  places,
  flights,
  property,
  suppliers,
  customers,
  orders,
  bankEntries,
  bankTransactions,
  expenses,
  invoices,
  regularInvoices,
  regularExpenses,
  bankStatements,
  taxEntries,
  documentTemplates,
  dashboard,
  news,
  internalBankTransfers,
  commissions,
  cashflow,
  offers,
  events,
})