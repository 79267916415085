import {
    REQUEST_BANK_TRANSACTIONS,
    RECEIVE_BANK_TRANSACTIONS,
    UPDATE_BANK_TRANSACTIONS,
    ERROR_BANK_TRANSACTIONS,
    RELOAD_BANK_TRANSACTIONS
} from '../types'
import {initialFetchState} from '../../constants/defaultValues'

export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_BANK_TRANSACTIONS:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_BANK_TRANSACTIONS:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                shouldReload: false,
                errors: {},
                message: '',
            }
        case RELOAD_BANK_TRANSACTIONS:
            return {
                ...state,
                shouldReload: true,
            }
        case UPDATE_BANK_TRANSACTIONS:
            const data = state.data
            let index = null
            switch(action.updateType) {
                case 'update':
                    if(data.items){
                        index = data.items.findIndex(item => item.id == action.id)
                        data.items[index] = action.payload
                    }
                    break
            }
            return {
                ...state,
                isFetching: false,
                data,
                errors: {}
            }
        case ERROR_BANK_TRANSACTIONS:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                message: action.errors.server || '',
            }
        default:
            return state
    }
}