import {
    SET_TAB
} from '../types'

export const setTab = (page, data) => {
    return (
        {
            type: SET_TAB,
            payload: data,
            page
        }
    )
}