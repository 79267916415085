import React, {useEffect} from "react"

import TopNav from "../containers/navs/Topnav"
import Sidebar from "../containers/navs/Sidebar"
import Toaster from "../containers/Toaster"

const AppLayout = (props) => {
    useEffect(() => {
        document.body.id = "app"
    }, [])
    const {hideSidebar} = props

    return (
        <div id="app-container">
            <TopNav/>
            {
                !hideSidebar && <Sidebar/>
            }
            <main className={hideSidebar ? "sidebar-hidden" : ""}>
                <div className="container-fluid">
                    {props.children}
                </div>
            </main>
            <Toaster/>
        </div>
    )
}

export default AppLayout