import {
    REQUEST_ROLES,
    RECEIVE_ROLES,
    UPDATE_ROLES,
    ERROR_ROLES
} from '../types'
import {fetchUrl} from '../../constants/constants'
import {requestItem, receiveItem, failedItem} from "../actions"
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import {reloadEnums} from "../actions"
import axios from 'axios'
import prepareFilters from "../../helpers/prepareFilters";

function request() {
    return {
        type: REQUEST_ROLES
    };
}

export function receive(data) {
    return {
        type: RECEIVE_ROLES,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_ROLES,
        errors: data
    }
}

function update(data, id, updateType) {
    return {
        type: UPDATE_ROLES,
        id,
        payload: data,
        updateType
    }
}

export function getRoles(params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().roles, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'roles',
                params: prepareFilters(params, getState(), "roles")
            }).then(res => {
                dispatch(receive(res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function getRole(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('role'))
            return axios({
                method: 'GET',
                url: fetchUrl + 'roles/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function createRole(data) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'POST',
                url: fetchUrl + 'roles',
                data
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_created'))
                dispatch(update(res.data.data, false, 'create'))
                dispatch(reloadEnums('roles'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function updateRole(data, id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('role'))
            return axios({
                method: 'PUT',
                url: fetchUrl + 'roles/' + id,
                data
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                dispatch(reloadEnums('roles'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}