import isEmpty from "lodash/isEmpty"

export default (fetch, skipIfExists) => {
    if (!fetch) {
        return true
    } else if (fetch.isFetching) {
        return false
    } else if (fetch.shouldReload) {
        return true
    } else if (skipIfExists) {
        if (isEmpty(fetch.data) || (fetch.validUntil && fetch.validUntil < Date.now())) {
            return true
        } else {
            return false
        }
    }
    return true
}