import {
    REQUEST_CURRENT_USER,
    RECEIVE_CURRENT_USER,
    ERROR_CURRENT_USER
} from '../types';

import axios from 'axios'
import jwtDecode from 'jwt-decode'

import {fetchUrl} from '../../constants/constants'
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import setAuthToken from '../../helpers/setAuthToken'
import {storage} from "../../constants/defaultValues"

export function receiveUser(user) {
    return {
        type: RECEIVE_CURRENT_USER,
        payload: user
    }
}

function requestUser() {
    return {
        type: REQUEST_CURRENT_USER
    }
}

function failedUser() {
    return {
        type: ERROR_CURRENT_USER
    };
}

export const loginUser = (data) => {
    return dispatch => (
        axios({
            method: 'POST',
            url: fetchUrl + 'login',
            data: data
        }).then(res => {
            const token = res.data.data.token
            if (token && jwtDecode(token)) {
                localStorage.setItem(storage.authentication, token);
                setAuthToken(token)
                return dispatch(getCurrentUser())
            } else {
                return fetchErrorCtrl({})
            }
        }).catch(err => {
            return fetchErrorCtrl(err)
        })
    )
}

export const forgotPassword = (data) => {
    return axios({
        method: 'POST',
        url: fetchUrl + 'send-reset-email',
        data: data
    }).then(res => {
        return true
    }).catch(err => {
        return fetchErrorCtrl(err)
    })
}

export const resetPassword = (data) => {
    return axios({
        method: 'POST',
        url: fetchUrl + 'reset-password',
        data: data
    }).then(res => {
        return true
    }).catch(err => {
        return fetchErrorCtrl(err)
    })
}

export const getCurrentUser = () => {
    return dispatch => {
        dispatch(requestUser())
        return axios({
            method: 'GET',
            url: fetchUrl + 'me'
        }).then(res => {
            dispatch(receiveUser(res.data.data))
            return true
        }).catch(err => {
            dispatch(failedUser())
            localStorage.removeItem(storage.authentication);
            setAuthToken(false)
            window.location.reload()
            return fetchErrorCtrl(err)
        })
    }
}

export const logoutUser = () => {
    return dispatch => {
        axios({
            method: 'POST',
            url: fetchUrl + 'logout'
        }).then().catch().then(() => {
            localStorage.removeItem(storage.authentication)
            setAuthToken(false)
            window.location.reload()
        })
    }

}

export const validateLink = (data) => {
    return axios({
        method: 'POST',
        url: fetchUrl + 'validate-link',
        data: data
    }).then(res => {
        return true
    }).catch(err => {
        return fetchErrorCtrl(err)
    })
}

export const changePassword = (data) => {
    return axios({
        method: 'POST',
        url: fetchUrl + 'change-password',
        data: data
    }).then(res => {
        return true
    }).catch(err => {
        return fetchErrorCtrl(err)
    })
}

export const updateProfile = (data) => {
    return dispatch => (
        axios({
            method: 'POST',
            url: fetchUrl + 'update-profile',
            data: data
        }).then(res => {
            dispatch(receiveUser(res.data.data))
            return true
        }).catch(err => {
            return fetchErrorCtrl(err)
        })
    )
}