import {
    REQUEST_PLACES,
    RECEIVE_PLACES,
    UPDATE_PLACES,
    ERROR_PLACES
} from '../types'
import {fetchUrl} from '../../constants/constants'
import {requestItem, receiveItem, failedItem} from "../actions"
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import {reloadEnums, getEnums} from "../actions"
import axios from 'axios'
import prepareFilters from "../../helpers/prepareFilters";

function request() {
    return {
        type: REQUEST_PLACES
    };
}

export function receive(data) {
    return {
        type: RECEIVE_PLACES,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_PLACES,
        errors: data
    }
}

function update(data, id, updateType) {
    return {
        type: UPDATE_PLACES,
        id,
        payload: data,
        updateType
    }
}

export function getPlaces(params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().places, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'places',
                params: prepareFilters(params, getState(), "places")
            }).then(res => {
                dispatch(receive(res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function getPlace(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('place'))
            return axios({
                method: 'GET',
                url: fetchUrl + 'places/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function createPlace(data, fromSelect = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            if(!fromSelect){
                dispatch(requestItem())
            }
            return axios({
                method: 'POST',
                url: fetchUrl + 'places',
                data
            }).then(res => {
                if(fromSelect){
                    dispatch(getEnums('places', {}, true));
                    return res.data.data.id
                }else{
                    dispatch(receiveItem({}, 'message.item_created'))
                    dispatch(update(res.data.data, false, 'create'))
                    dispatch(reloadEnums('places'))
                }
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function updatePlace(data, id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('place'))
            return axios({
                method: 'PUT',
                url: fetchUrl + 'places/' + id,
                data
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                dispatch(reloadEnums('places'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function deletePlace(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'DELETE',
                url: fetchUrl + 'places/' + id
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_deleted'))
                dispatch(update(res.data.data, id, 'delete'))
                dispatch(reloadEnums('places'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}