import {
    REQUEST_INVOICES,
    RECEIVE_INVOICES,
    UPDATE_INVOICES,
    ERROR_INVOICES
} from '../types'
import {fetchUrl} from '../../constants/constants'
import {requestItem, receiveItem, failedItem, updateItem, reloadDashboard} from "../actions"
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import {reloadEnums} from "../actions"
import axios from 'axios'
import prepareFilters from "../../helpers/prepareFilters";

function request() {
    return {
        type: REQUEST_INVOICES
    };
}

export function receive(data) {
    return {
        type: RECEIVE_INVOICES,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_INVOICES,
        errors: data
    }
}

function update(data, id, updateType) {
    return {
        type: UPDATE_INVOICES,
        id,
        payload: data,
        updateType
    }
}

export function getInvoices(params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().invoices, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'invoices',
                params: prepareFilters(params, getState(), "invoices")
            }).then(res => {
                dispatch(receive(res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function getInvoice(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('invoice'))
            return axios({
                method: 'GET',
                url: fetchUrl + 'invoices/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function createInvoice(data) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'POST',
                url: fetchUrl + 'invoices',
                data
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_created'))
                dispatch(update(res.data.data, false, 'create'))
                dispatch(reloadEnums('invoices'))
                dispatch(reloadEnums('invoices.proforma'))
                dispatch(reloadDashboard())
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function updateInvoice(data, id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('invoice'))
            return axios({
                method: 'PUT',
                url: fetchUrl + 'invoices/' + id,
                data
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                dispatch(reloadEnums('invoices'))
                dispatch(reloadEnums('invoices.proforma'))
                dispatch(reloadDashboard())
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function toggleAccountedInvoice(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('invoice'))
            return axios({
                method: 'PATCH',
                url: fetchUrl + 'invoices/set-accounted/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function togglePrintedInvoice(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('invoice'))
            return axios({
                method: 'PATCH',
                url: fetchUrl + 'invoices/set-printed/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function deleteInvoice(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'DELETE',
                url: fetchUrl + 'invoices/' + id
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_deleted'))
                dispatch(update(res.data.data, id, 'delete'))
                dispatch(reloadEnums('invoices'))
                dispatch(reloadEnums('invoices.proforma'))
                dispatch(reloadDashboard())
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function sendInvoice(data, id) {
    return (dispatch, getState) => {
        return axios({
            method: 'POST',
            url: fetchUrl + 'invoices/email/' + id,
            data
        }).then(res => {
                dispatch(updateItem(res.data.data, 'invoiceSentLog'))
                return true
            }
        ).catch(err => {
            return false
        })
    }
}