import {
    SET_TOASTER
} from '../types'

function setToaster(payload) {
    return {
        type: SET_TOASTER,
        payload
    }
}

export function toastSuccess(message) {
    return dispatch => dispatch(
        setToaster({
            toasterType: "OK",
            title: 'general.success',
            message
        }))
}

export function toastError(message) {
    return dispatch => dispatch(
        setToaster({
            toasterType: "ERROR",
            title: 'general.error',
            message
        }))
}

export function toastInfo(message) {
    return dispatch => dispatch(
        setToaster({
            toasterType: "INFO",
            title: 'general.info',
            message
        }))
}

