import {
    REQUEST_ITEM,
    RECEIVE_ITEM,
    UPDATE_ITEM,
    ERROR_ITEM
} from '../types'
import {initialFetchState} from '../../constants/defaultValues'
import _isObject from 'lodash/isObject'

export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_ITEM:
            return {
                ...state,
                isFetching: true,
                key: action.payload,
                message: '',
                errors: {}
            }
        case RECEIVE_ITEM:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                errors: {},
                message: action.message,
            }
        case UPDATE_ITEM:
            const data = state.data
            switch(action.updateType) {
                case 'loginLog':
                    data.logs.login = action.payload
                    break
                case 'pilotFlights':
                case 'droneFlights':
                    data.flights = action.payload
                    break
                case 'supplierProperty':
                    data.property = action.payload
                    break
                case 'supplierExpenses':
                case 'orderExpenses':
                    data.expenses = action.payload
                    break
                case 'customerOrders':
                    data.orders = action.payload
                    break
                case 'customerInvoices':
                case 'orderInvoices':
                    data.invoices = action.payload
                    break
                case 'invoiceSentLog':
                case 'documentSentLog':
                    data.sentLog.unshift(action.payload)
                    break
                case 'regularInvoiceInvoices':
                    data.invoices = action.payload
                    break
                case 'customerDocuments':
                case 'orderDocuments':
                    data.documents = action.payload
                    break
                case 'customerPdfDocuments':
                case 'orderPdfDocuments':
                case 'documentPdfDocuments':
                    data.pdfDocuments = action.payload
                    break
                case 'warehouse_items':
                    data.warehouse_items = action.payload
                    break
                case 'prophylaxes':
                    data.prophylaxes = action.payload
                    break
            }
            return {
                ...state,
                isFetching: false,
                data
            }
        case ERROR_ITEM:
            return {
                ...state,
                isFetching: false,
                errors: action.payload,
                message: _isObject(action.payload) && action.payload.server ? action.payload.server : ''
            }
        default:
            return state
    }
}