import React, {useState} from "react"
import {bindActionCreators} from "redux"
import {connect} from "react-redux"
import Switch from "rc-switch"
import "rc-switch/assets/index.css"
import {changeColorTheme} from '../../redux/actions'

const TopnavDarkSwitch = (props) => {

    const [switchChecked, toggleSwitch] = useState(props.colorTheme.indexOf('dark') > -1)

    function changeMode() {
        let color = props.colorTheme

        if (color.indexOf('dark') > -1) {
            color = color.replace('dark', 'light')
        } else if (color.indexOf('light') > -1) {
            color = color.replace('light', 'dark')
        }

        toggleSwitch(color.indexOf('dark') > -1)
        props.actions.changeColorTheme(color)
        setTimeout(() => {
            window.location.reload();
        }, 500);
    };

    return (
        <div className="d-none d-md-inline-block align-middle">
            <Switch
                id="Tooltip-Switch"
                className="custom-switch custom-switch-primary custom-switch-small"
                checked={switchChecked}
                onChange={() => changeMode()}
            />
        </div>
    )

}

function mapStateToProps(state) {
    return {
        colorTheme: state.colorTheme,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            changeColorTheme,
        }, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TopnavDarkSwitch)
