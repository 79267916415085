import React, {useEffect} from "react"
import {connect} from "react-redux"
import {Badge} from "react-bootstrap"
import IntlMessages from "../../helpers/IntlMessages";
import {bindActionCreators} from "redux";
import {getEnums} from "../../redux/enums/actions";

const ItemPermissions = (props) => {
    const {enums, permissions, field} = props
    const enumType = field.enumType
    useEffect(() => {
        if (!enums) {
            props.actions.getEnums(enumType)
        }
    }, [enums])

    if (!enums || enums.isFetching) {
        return <span>...</span>
    }
    const perms = permissions.map(p => enums.data[p])

    const descriptions = {}
    const sections = perms.reduce((acc, item, index) => {
        const s = item.section
        descriptions[s] = item.description

        if (!acc[s]) {
            acc[s] = []
        }
        acc[s].push(
            <Badge pill variant='primary' className="mr-1 mb-1" key={index}>
                <IntlMessages id={`enum.${item.label}`}/>
            </Badge>
        )
        return acc
    }, {})

    return (
        <>
            {
                Object.keys(sections).map(key => (
                    <div className="mb-3" key={key}>
                        <p xs={12}>
                            <h6>{descriptions[key]}</h6>
                        </p>
                        {sections[key].length ? sections[key] : '--'}
                    </div>
                ))
            }
        </>
    )
}

function mapStateToProps(state, ownProps) {
    return {
        enums: state.enums[ownProps.field.enumType] || false
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getEnums
        }, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ItemPermissions)