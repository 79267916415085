import {
    REQUEST_NEWS,
    RECEIVE_NEWS,
    UPDATE_NEWS,
    ERROR_NEWS
} from '../types'
import {fetchUrl} from '../../constants/constants'
import {requestItem, receiveItem, failedItem, updateDashboard} from "../actions"
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'
import prepareFilters from "../../helpers/prepareFilters";

function request() {
    return {
        type: REQUEST_NEWS
    };
}

export function receive(data) {
    return {
        type: RECEIVE_NEWS,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_NEWS,
        errors: data
    }
}

function update(data, id, updateType) {
    return {
        type: UPDATE_NEWS,
        id,
        payload: data,
        updateType
    }
}

export function getNews(params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().news, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'news',
                params: prepareFilters(params, getState(), "news")
            }).then(res => {
                dispatch(receive(res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function getNewsItem(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('news'))
            return axios({
                method: 'GET',
                url: fetchUrl + 'news/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function createNews(data, fromSelect = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            if(!fromSelect){
                dispatch(requestItem())
            }
            return axios({
                method: 'POST',
                url: fetchUrl + 'news',
                data
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_created'))
                dispatch(update(res.data.data, false, 'create'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function updateNews(data, id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('news'))
            return axios({
                method: 'PUT',
                url: fetchUrl + 'news/' + id,
                data
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function deleteNews(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'DELETE',
                url: fetchUrl + 'news/' + id
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_deleted'))
                dispatch(update(res.data.data, id, 'delete'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function setNewsRead(id) {
    return (dispatch, getState) => {
            return axios({
                method: 'PATCH',
                url: fetchUrl + 'news/' + id
            }).then(res => {
                dispatch(updateDashboard('news', res.data.data, id))
                return true
            }).catch(err => {
                return false
            })
    }
}