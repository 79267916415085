import React from "react"
import {connect} from "react-redux"

const Avatar = (props) => {
    return (
            <span className="avatar-placeholder"
                  role="img">{props.auth.user.name ? props.auth.user.name[0] : '--'}</span>
    )
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    }
}

export default connect(
    mapStateToProps,
    null
)(Avatar)