import React from "react"
import {Col} from 'react-bootstrap'
import Error from "../svg/Error"
import IntlMessages from "../../helpers/IntlMessages"

export default (props) => {
    const content = (
        <div className="empty-message">
            <div>
                <Error/>
                {
                    props.server ? (
                        <div className="text-center">
                            <h4>CHYBA 500</h4>
                            <p>Pracujeme na vyřešení problému.</p>
                        </div>
                    ) : (
                        <div className="text-center">
                            <h4><IntlMessages id={props.message || 'message.unexpected_error'}/></h4>
                            {
                                props.hiddenInfo ? null : <p><IntlMessages id={props.info || 'general.error-info'}/></p>
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
    return(
        props.as === 'col' ? <Col>
            {content}
        </Col> : <div>
            {content}
        </div>
    )
}