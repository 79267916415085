import {
    REQUEST_EXPENSES,
    RECEIVE_EXPENSES,
    UPDATE_EXPENSES,
    ERROR_EXPENSES,
    RELOAD_EXPENSES
} from '../types'
import {initialFetchState} from '../../constants/defaultValues'
import {listRefresh} from "../../constants/constants";

export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_EXPENSES:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_EXPENSES:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                errors: {},
                message: '',
                validUntil: listRefresh.default + Date.now(),
            }
        case RELOAD_EXPENSES:
            return {
                ...state,
                shouldReload: true,
            }
        case UPDATE_EXPENSES:
            const data = state.data
            let index = null
            switch(action.updateType) {
                case 'create':
                    data.items.unshift(action.payload)
                    break
                case 'update':
                    if(data.items){
                        index = data.items.findIndex(item => item.id == action.id)
                        data.items[index] = action.payload
                    }
                    break
                case 'delete':
                    index = data.items.findIndex(item => item.id == action.id)
                    data.items.splice(index, 1)
                    break
            }
            return {
                ...state,
                isFetching: false,
                data,
                errors: {}
            }
        case ERROR_EXPENSES:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                message: action.errors.server || '',
            }
        default:
            return state
    }
}