import {
    REQUEST_DASHBOARD,
    RECEIVE_DASHBOARD,
    RELOAD_DASHBOARD,
    ERROR_DASHBOARD,
    UPDATE_DASHBOARD
} from '../types'
import {initialFetchState} from '../../constants/defaultValues'
import {listRefresh} from "../../constants/constants"
import _set from "lodash/set"

export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_DASHBOARD:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_DASHBOARD:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                errors: {},
                message: '',
                shouldReload: false,
                validUntil: listRefresh.default + Date.now(),
            }
        case RELOAD_DASHBOARD:
            return {
                ...state,
                shouldReload: true,
            }
        case UPDATE_DASHBOARD:
            const data = {...state.data}
            if(action.section == 'news' || action.section == 'notifications'){
                let index = null
                index = data[action.section].findIndex(item => item.id == action.id)
                const section = [...data[action.section]]
                section[index] = action.payload
                data[action.section] = section
            }
            if(action.section == 'events'){
                data[action.section] = action.payload
            }
            return {
                ...state,
                isFetching: false,
                data
            }
        case ERROR_DASHBOARD:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                message: action.errors.server || '',
            }
        default:
            return state
    }
}