import React, {useEffect} from 'react'
import {Container} from "react-bootstrap";

const SinglePageLayout =  (props) => {

    useEffect(() => {
        document.body.id = "app"
    }, [])

    return(
        <main className={`single-page ${props.isDocumentSignature ? 'document-signature' : ''}`}>
            {
                props.isDocumentSignature ? (
                    <div className="py-4">
                        {props.children}
                    </div>
                ):(
                    <>
                        <img src={`/assets/img/logo/logo.svg`} alt="logo" className="img"/>
                        <div className="content-wrapper d-flex align-items-center justify-content-center justify-content-lg-end">
                            <div className="depth-2">
                                {props.children}
                            </div>
                        </div>
                    </>
                )
            }
        </main>
    )
}

export default SinglePageLayout